import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import ContentCarousel from '../components/ContentCarousel';
import { getRandomContent, getWeeklyChallenges } from '../services/firebase';
import { ContentItem, DraftLotteryVideo, WaitlistFormData } from '../types';
import { WeeklyChallenge } from '../components/WeeklyChallengeForm';
import { Widget } from '@typeform/embed-react';
import Navbar from '../components/Navbar';
import ComingSoonSection from '../components/ComingSoon';
import TypeFormModal from '../components/ui/TypeFormModal';
import { useModal } from '../contexts/ModalContext';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';

// Custom hook for measuring element height
const useHeight = ({ on = true } = {}) => {
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!on || !ref.current) return;
    
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [on]);

  return { height, ref };
};


// Helper component for the rotated arrow
const ArrowIcon: React.FC<{ className?: string }> = ({ className = "w-4 h-4" }) => (
  <svg 
    viewBox="0 0 24 24" 
    fill="none" 
    className={className}
    stroke="currentColor"
    strokeWidth={2.5}
  >
    <path 
      d="M7 17L17 7M17 7H8M17 7V16" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

const AccordionItem: React.FC<{
  challenge: WeeklyChallenge;
  isExpanded: boolean;
  onToggle: () => void;
  onViewDetails: () => void;
}> = ({ challenge, isExpanded, onToggle, onViewDetails }) => {
  const { height, ref } = useHeight({ on: isExpanded });

  return (
    <div className="bg-[#1A1A1A] border-white/40 border-b-2 py-4">
      <div className="relative">
        {/* Week tab - Only shown when expanded */}
        <div 
          className={`
            absolute left-6 -top-2 transition-all duration-300 
            ${isExpanded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'}
          `}
        >
          <div className="bg-[#18f7b1] text-black px-3 py-1 rounded-md text-sm font-bold text-center font-antonio">
            Week {challenge.week}
          </div>
        </div>

        <button
          onClick={onToggle}
          className="w-full flex items-center justify-between text-white 
                   hover:bg-white/5 transition-colors"
        >
          <div className={`flex items-center space-x-4 py-4 px-6 ${isExpanded ? 'mt-4' : ''}`}>
            <h4 className="text-2xl font-antonio">{challenge.name}</h4>
          </div>
        </button>
        
        <button
          onClick={onViewDetails}
          className="absolute right-6 top-1/2 -translate-y-1/2
                   bg-[#18f7b1] p-2 rounded-md text-black 
                   hover:scale-110 transition-transform duration-200"
          aria-label="View details"
        >
          <ArrowIcon className="w-4 h-4" />
        </button>
      </div>
      
      <div 
        className="overflow-hidden transition-[height] duration-300 ease-in-out"
        style={{ height: isExpanded ? height : 0 }}
      >
        <div ref={ref}>
          <div className="px-6 pt-2 pb-6 text-white/80">
            <p className="text-lg mb-6">
              {challenge.description}
            </p>
            <div className="flex flex-wrap gap-2">
              {(challenge.positions || []).map((position) => (
                <span 
                  key={position} 
                  className="bg-black/30 px-3 py-1 rounded-md text-sm text-white/90"
                >
                  {position}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      <div className="h-[1px] bg-white/10 last:hidden" />
    </div>
  );
};

const HomePage: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const [challenges, setChallenges] = useState<WeeklyChallenge[]>([]);
  const [selectedChallenge, setSelectedChallenge] = useState<WeeklyChallenge | null>(null);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { isTypeFormOpen, closeTypeForm } = useModal();
  const [isMobile, setIsMobile] = useState(false);

// Add this useEffect hook to detect mobile screens
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  // Set initial value
  handleResize();

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Cleanup
  return () => window.removeEventListener('resize', handleResize);
}, []);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getRandomContent(20);
        setCarouselItems(content);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };
    fetchContent();
  }, []);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const fetchedChallenges = await getWeeklyChallenges();
        const sortedChallenges = fetchedChallenges.sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return dateB - dateA;
        });
        setChallenges(sortedChallenges);
        if (sortedChallenges.length > 0) {
          setSelectedChallenge(sortedChallenges[0]);
          if (sortedChallenges[0].id) {
            setExpandedId(sortedChallenges[0].id);
          }
        }
      } catch (error) {
        console.error('Error fetching challenges:', error);
      }
    };
    fetchChallenges();
  }, []);

  const handleItemClick = (item: ContentItem | DraftLotteryVideo) => {
    if ('type' in item) {
      navigate(`/punishments/${item.id}`);
    } else {
      navigate(`/draft-lottery/${item.id}`);
    }
  };

  const handleChallengeClick = (challenge: WeeklyChallenge) => {
    setSelectedChallenge(challenge);
    if (challenge.id) {
      setExpandedId(expandedId === challenge.id ? null : challenge.id);
    }
  };

  const handleViewChallengeClick = (challengeId: string | undefined) => {
    if (challengeId) {
      navigate(`/challenges/${challengeId}`);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white text-black">
      <Navbar />
      
      <div className="bg-[#1A1A1A]">
      <header className="relative w-full mx-auto py-8 sm:py-12 lg:py-16 px-4 sm:px-6 lg:px-8 mt-[-40px]">
      {/* Main container with max width and auto margins */}
      <div className="relative max-w-[1700px] mx-auto">
        {/* Aspect ratio container */}
        <div className={`relative w-full ${isMobile ? 'aspect-[4/6]' : 'aspect-[1395/806]'} rounded-[40px] overflow-hidden`}>
          {/* Background Image */}
          <img
            className="absolute inset-0 w-full h-full object-cover "
            src={require("../assets/HeroImageFinal.png")}
            alt="Fantasy Sports Background"
          />
          
          {/* Overlays */}
          <div className="absolute inset-0 z-10 sm:z-0 bg-black/40 sm:bg-[#1a1a1a]/30" />
          <div className="absolute inset-0 bg-black/60 sm:hidden" />
          
          {/* Content Container */}
          <div className="absolute inset-0 flex flex-col justify-center p-8 sm:p-12 z-20 mt-[20%] lg:ml-[3%]">
            <div className="max-w-2xl">
            <h1 className="text-5xl sm:text-8xl font-antonio font-bold text-white leading-tight">
  Losing is <br />
  Winning
</h1>
              
<p className="text-2xl text-[#00FCB3] font-thin font-antonio mt-1 sm:mt-4">
  When you lose to your friends
</p>
              
            {/* Button Container */}
            <div className="flex flex-col sm:flex-row sm:items-left gap-3 sm:gap-4 mt-4 sm:mt-8">
              <Link
              to="/sign-up"
              className="text-white text-center text-lg sm:text-xl max-w-[200px] font-antonio bg-transparent border border-primary-500 hover:bg-black/60 hover:text-white transition-colors duration-300 py-3 sm:py-4 px-6 sm:px-8 rounded-md whitespace-nowrap"
              >
                Sign Up
              </Link>
              <Link
              to="/login"
              className="bg-primary-500 max-w-[300px] font-antonio hover:bg-black/60 text-white text-lg sm:text-xl transition-colors duration-300 py-3 sm:py-4 px-6 sm:px-8 rounded-md flex items-center whitespace-nowrap"
               >
                Register Your League
              <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </div>




      <main className="flex-grow flex flex-col">
        <br/>
        <br/>

      {/* Top Punishments Section in HomePage.tsx */}
<section className="w-full px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
  <div className="max-w-[1440px] mx-auto">
    {/* Header Section */}
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-16 mb-8 lg:mb-12">
      {/* Title */}
      <div>
        <h2 className="text-3xl sm:text-4xl lg:text-6xl font-bold leading-none font-antonio text-black mb-4 uppercase">
          Top Loser
          <br />
          Punishments
        </h2>
      </div>

      {/* Description and Button */}
      <div className="flex flex-col justify-between">
        <p className="text-lg sm:text-xl lg:text-3xl mb-6 font-antonio text-[#1A1A1A]">
          Check out our Loser Punishment Library to see all of the punishments Fantasy Loser has to offer
        </p>
        <div>
          <button
            onClick={() => navigate('/explore')}
            className="w-full sm:w-auto bg-white border-2 border-black text-black px-4 sm:px-6 py-3 sm:py-4 
                     rounded-md hover:bg-black hover:text-white transition-colors duration-300
                     font-source text-sm sm:text-base lg:text-lg"
          >
            View Punishments
          </button>
        </div>
      </div>
    </div>

    {/* Carousel Section */}
    <div className="relative mt-8 sm:mt-12">
      <ContentCarousel
        items={carouselItems}
        onItemClick={handleItemClick}
      />
    </div>
  </div>
</section>

        {/* Weekly Challenge Section */}
        <section className="bg-[#1A1A1A] py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-6xl font-antonio text-white mb-12">The Weekly Challenge</h2>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Left Column - Featured Challenge */}
              <div className="relative group">
                {selectedChallenge && selectedChallenge.id && (
                  <>
                    <div className="relative h-[700px] overflow-hidden rounded-xl transition-transform duration-300 group-hover:scale-[1.02]">
                      <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/20 to-black/90 z-10" />
                      <img 
                        src={typeof selectedChallenge.image === 'string' ? selectedChallenge.image : '/placeholder-image.jpg'}
                        alt={selectedChallenge.name || 'Selected challenge'}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute font-roboto top-6 left-6 z-20 flex items-center space-x-4">
                        <div className="bg-[#18f7b1] text-black px-3 py-1 rounded-md text-sm font-bold">
                          Week {selectedChallenge.week}
                        </div>
                      </div>

                      <h3 className="absolute bottom-6 left-6 text-6xl font-antonio text-white z-20">
                        {selectedChallenge.name}
                      </h3>
                    </div>
                  </>
                )}
              </div>

              {/* Right Column - Challenge List */}
              <div>
  {challenges.map((challenge) => (
    <AccordionItem
      key={challenge.id || Math.random().toString()}
      challenge={challenge}
      isExpanded={expandedId === challenge.id}
      onToggle={() => handleChallengeClick(challenge)}
      onViewDetails={() => handleViewChallengeClick(challenge.id)}
    />
  ))}
  
  <button
    onClick={() => navigate('/challenges')}
    className="w-[40%] py-4 mt-6 text-white border border-white/20 rounded-md
               hover:bg-white/5 transition-all duration-300 
               font-antonio text-xl uppercase tracking-wide"
  >
    View Challenges
  </button>
</div>
            </div>
          </div>
        </section>

        {/* Coming Soon Section */}
        <ComingSoonSection />

              {/* New Section - Connected Platforms */}
              <section className="container mx-auto px-4 mb-12 text-center mt-10">
  {/* Title */}
  <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold justify-center font-antonio text-black mb-20 uppercase">
    Import Your Fantasy Leagues
  </h2>

  {/* Image Grid */}
  <div className="flex flex-col sm:flex-row justify-center items-center space-y-8 sm:space-y-0 sm:space-x-8 mb-10 sm:gap-20">
    {/* Image 1 */}
    <div className="relative group">
      <div className="relative w-40 sm:w-48 overflow-hidden rounded-lg transition-transform duration-300 group-hover:scale-[1.2]">
        <img 
          src={require("../assets/yahoo.png")}
          alt="Yahoo"
          className="w-full h-auto object-contain"
        />
      </div>
    </div>

    {/* Image 2 */}
    <div className="relative group">
      <div className="relative w-40 sm:w-48 overflow-hidden rounded-lg transition-transform duration-300 group-hover:scale-[1.2]">
        <img 
          src={require("../assets/ESPN_wordmark.png")}
          alt="ESPN"
          className="w-full h-auto object-contain"
        />
      </div>
    </div>

    {/* Image 3 */}
    <div className="relative group">
      <div className="relative w-48 sm:w-60 overflow-hidden rounded-lg transition-transform duration-300 group-hover:scale-[1.2]">
        <img 
          src={require("../assets/sleeper.png")}
          alt="Sleeper"
          className="w-full h-auto object-contain"
        />
      </div>
    </div>
  </div>
</section>

        <TypeFormModal 
        isOpen={isTypeFormOpen} 
        onClose={closeTypeForm} 
      />
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;