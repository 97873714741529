import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../services/firebase';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTheme } from '../contexts/ThemeContext';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';

const PasswordResetPage: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { theme } = useTheme();
  const location = useLocation();

  useEffect(() => {
    const oobCode = new URLSearchParams(location.search).get('oobCode');
    if (oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          setEmail(email);
          setLoading(false);
        })
        .catch((error) => {
          setError('Invalid or expired reset link. Please try again.');
          setLoading(false);
        });
    } else {
      setError('No reset code found. Please try resetting your password again.');
      setLoading(false);
    }
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    const oobCode = new URLSearchParams(location.search).get('oobCode');
    if (!oobCode) {
      setError('No reset code found. Please try resetting your password again.');
      return;
    }
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccess(true);
      setError('');
    } catch (error) {
      setError('Failed to reset password. Please try again.');
    }
  };

  if (loading) {
    return <StyledLoadingSpinner/>;
  }

  return (
    <div className={`min-h-screen flex flex-col ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      <div className="flex-grow flex flex-col justify-center items-center px-4">
        <h2 className="text-2xl font-bold mb-6 text-center">Reset Your Password</h2>
        <div className="w-full max-w-md bg-gray-300 p-8 border border-gray-200 rounded-lg">
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          {success ? (
            <div className="text-center">
              <p className="text-green-500 mb-4">Your password has been successfully reset.</p>
              <button
                onClick={() => navigate('/login')}
                className="w-full px-4 py-2 font-bold text-black bg-[#18f7b1] rounded-full hover:bg-[#18f7b0b4] focus:outline-none focus:shadow-outline"
              >
                Go to Login
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <p className="mb-4">Resetting password for: {email}</p>
              <div className="mb-4">
                <label htmlFor="newPassword" className="block mb-2 text-sm font-bold text-gray-700">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="confirmPassword" className="block mb-2 text-sm font-bold text-gray-700">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6 text-center">
                <button
                  className="w-full px-4 py-2 font-bold text-black bg-[#18f7b1] rounded-full hover:bg-[#18f7b0b4] focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordResetPage;