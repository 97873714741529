import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, Auth, sendPasswordResetEmail, signInWithCredential, EmailAuthProvider } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit, Firestore, doc, getDoc, setDoc, where, updateDoc, increment, runTransaction, deleteDoc } from 'firebase/firestore';
import { getStorage, ref as storeRef, uploadBytes, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { ContentItem, WaitlistFormData, DraftLotteryVideo, NewContentItem } from '../types';
import { firebaseConfig } from '../firebaseConfig';
import axios from 'axios';
import { YahooLeague, SeasonRecord } from '../types/yahoo';
import { createYahooSeasonHandler } from './yahoo-season-records';
import { WeeklyChallenge, WeeklyChallengeUpdateData } from '../components/WeeklyChallengeForm';
import { XMLParser } from 'fast-xml-parser';

interface YahooApiResponse {
  fantasy_content: {
    users: {
      user: {
        games: {
          game: {
            game_key: string;
            game_id: string;
            name: string;
            code: string;
            type: string;
            url: string;
            season: string;
            is_registration_over: string;
            is_game_over: string;
            is_offseason: string;
            leagues: {
              league: YahooLeague | YahooLeague[];
            };
          };
        };
      };
    };
  };
}



export interface League {
  id: string;
  imageUrl?: string;
  bannerImageUrl?: string;
  name: string;
  commissionerId: string;
  commish: string;
  yearStarted: string;
  mostRecentChamp: string;
  recentLoser: string;
  leagueUrl: string;
  isPasswordProtected: boolean;
  password?: string;
  yahooLeagueId?: string;
  yahooLeague?: YahooLeague;
  renewed?: string;
  recordBookLastUpdated: number; 
  punishment?: {
    title: string;
    link: string;
    status: 'approved' | 'denied' | 'pending';
    selectedPunishment: string;
    customPunishment: string;
  };
  mostRecentLoser: {
    name: string;
    fantasySeason: string;
    record: string;
    winPercentage: string;
    totalSeasonPoints: string;
    totalPointsRanking: string;
    imageUrl: string;
  };
  deadbeatTracker: Array<{
    team: string;
    status: 'PAID' | 'DEADBEAT' | 'HALF DEADBEAT';
    balance: number;
    datePaid: string;
  }>;
  videoLibrary: string[];
  playerProfiles: string[];
  recordBook: SeasonRecord[];
  teamAssociations?: {
    teamId: string;
    email: string;
    name: string;
    joinedAt: number;
  }[];
  inviteCode?: string;
  inviteAttempts?: {
    [ip: string]: {
      count: number;
      lastAttempt: number;
    };
  };
}

interface PreviousSeasonKeys {
  gameKey: string;
  leagueId: string;
}



const app = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
export const db: Firestore = getFirestore(app);
export const storage: FirebaseStorage = getStorage(app);

if (!storage) {
  console.error('Firebase Storage is not initialized properly');
}

export const loginUser = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const uploadVideo = async (file: File): Promise<string> => {
  const storageRef = storeRef(storage, `videos/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};

export const registerUser = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const logoutUser = () => {
  return signOut(auth);
};

export const addToWaitlist = async (formData: WaitlistFormData) => {
  try {
    const docRef = await addDoc(collection(db, 'waitlist'), formData);
    console.log('Document written with ID: ', docRef.id);
    return docRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e;
  }
};


export const addContent = async (contentData: Omit<ContentItem, 'id'>): Promise<string> => {
  try {
    let imageUrl = contentData.image;

    // If contentData.image is a File object, upload it and get the URL
    if (contentData.image instanceof File) {
      const storageRef = storeRef(storage, `images/${Date.now()}_${contentData.image.name}`);
      const snapshot = await uploadBytes(storageRef, contentData.image);
      imageUrl = await getDownloadURL(snapshot.ref);
    }

    // Create a new object without the image property and without an id
    const { image, ...dataToSave } = contentData;

    // Add the document to Firestore
    const docRef = await addDoc(collection(db, 'content'), {
      ...dataToSave,
      image: imageUrl,
      createdAt: new Date(),
    });

    // Return the Firestore-generated document ID
    return docRef.id;
  } catch (error) {
    console.error('Error adding content:', error);
    throw error;
  }
};

export const getAllContent = async (): Promise<ContentItem[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'content'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ContentItem));
  } catch (e) {
    console.error('Error getting all content: ', e);
    throw e;
  }
};

export const getRandomContent = async (count: number, extraForFiltering: boolean = false): Promise<ContentItem[]> => {
  try {
    const fetchCount = extraForFiltering ? count * 2 : count;
    const querySnapshot = await getDocs(query(collection(db, 'content'), orderBy('createdAt', 'desc'), limit(fetchCount)));
    const allItems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ContentItem));
    return allItems.sort(() => 0.5 - Math.random()).slice(0, count);
  } catch (e) {
    console.error('Error getting random content: ', e);
    throw e;
  }
};

export const uploadImage = async (image: File): Promise<string> => {
  const storageRef = storeRef(storage, `images/${Date.now()}_${image.name}`);
  await uploadBytes(storageRef, image);
  return getDownloadURL(storageRef);
};

export const getUserRole = async (userId: string): Promise<string | null> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.data()?.role || null;
  } catch (e) {
    console.error('Error getting user role: ', e);
    throw e;
  }
};

export const setUserRole = async (userId: string, role: string): Promise<void> => {
  try {
    await setDoc(doc(db, 'users', userId), { role }, { merge: true });
  } catch (e) {
    console.error('Error setting user role: ', e);
    throw e;
  }
};

export const getContentByTag = async (tag: string): Promise<any[]> => {
  try {
    const q = query(collection(db, 'content'), where('tags', 'array-contains', tag));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (e) {
    console.error(`Error getting content with tag ${tag}: `, e);
    throw e;
  }
};

export const getDraftLotteryVideos = async (): Promise<DraftLotteryVideo[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'draftLotteryVideos'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as DraftLotteryVideo));
  } catch (e) {
    console.error('Error getting draft lottery videos: ', e);
    throw e;
  }
};

export const uploadDraftLotteryVideo = async (video: Omit<DraftLotteryVideo, 'id' | 'videoUrl' | 'createdAt'>, file: File): Promise<void> => {
  try {
    const videoUrl = await uploadVideo(file);

    await addDoc(collection(db, 'draftLotteryVideos'), {
      ...video,
      videoUrl,
      createdAt: new Date()
    });
  } catch (e) {
    console.error('Error uploading draft lottery video: ', e);
    throw e;
  }
};

export const submitFeedback = async (feedback: {
  type: 'punishment' | 'draft' | 'challenge';
  title: string;
  description: string;
  userId: string;
}) => {
  try {
    await addDoc(collection(db, 'feedback'), {
      ...feedback,
      createdAt: new Date()
    });
  } catch (e) {
    console.error('Error submitting feedback: ', e);
    throw e;
  }
};

export const getContentById = async (id: string): Promise<ContentItem | DraftLotteryVideo | null> => {
  try {
    // First, try to fetch from 'content' collection
    let docRef = doc(db, 'content', id);
    let docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as ContentItem;
    }

    // If not found, try 'draftLotteryVideos' collection
    docRef = doc(db, 'draftLotteryVideos', id);
    docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as DraftLotteryVideo;
    }

    // If not found in either collection, return null
    return null;
  } catch (e) {
    console.error('Error getting content by id: ', e);
    throw e;
  }
};

export const createLeague = async (leagueData: Omit<League, 'id'>): Promise<string> => {
  try {
    // Create a new object without the password if it's not provided
    const leagueDataToSave = {
      ...leagueData,
      createdAt: new Date()
    };

    // Remove the password field if it's undefined
    if (leagueDataToSave.password === undefined) {
      delete leagueDataToSave.password;
    }

    const docRef = await addDoc(collection(db, 'leagues'), leagueDataToSave);
    return docRef.id;
  } catch (e) {
    console.error('Error creating league: ', e);
    throw e;
  }
};


export const getLeagueById = async (id: string): Promise<League | null> => {
  try {
    const docRef = doc(db, 'leagues', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const league = { id: docSnap.id, ...docSnap.data() } as League;
      
      // If the league has a Yahoo league ID, fetch the Yahoo league data
      if (league.yahooLeagueId) {
        const yahooLeagueRef = doc(db, 'yahooLeagues', league.yahooLeagueId);
        const yahooLeagueSnap = await getDoc(yahooLeagueRef);
        
        if (yahooLeagueSnap.exists()) {
          league.yahooLeague = yahooLeagueSnap.data() as YahooLeague;
        }
      }
      
      // Remove the password field before returning the league data
      if (league.password) {
        delete league.password;
      }
      return league;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error getting league by id: ', e);
    throw e;
  }
};

export const updateLeague = async (id: string, leagueData: Partial<League>): Promise<void> => {
  try {
    const leagueRef = doc(db, 'leagues', id);
    
    // Remove any undefined fields from leagueData
    const cleanedData = Object.fromEntries(
      Object.entries(leagueData).filter(([_, value]) => value !== undefined)
    );

    await updateDoc(leagueRef, cleanedData);
  } catch (e) {
    console.error('Error updating league:', e);
    throw e;
  }
};


export const getLeaguesByCommissioner = async (commissionerId: string): Promise<League[]> => {
  try {
    const q = query(collection(db, 'leagues'), where('commissionerId', '==', commissionerId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const league = { id: doc.id, ...doc.data() } as League;
      // Remove the password field before returning the league data
      if (league.password) {
        delete league.password;
      }
      return league;
    });
  } catch (e) {
    console.error('Error getting leagues by commissioner: ', e);
    throw e;
  }
};


export const verifyLeaguePassword = async (leagueId: string, password: string): Promise<boolean> => {
  try {
    const docRef = doc(db, 'leagues', leagueId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const league = docSnap.data() as League;
      return league.isPasswordProtected ? league.password === password : true;
    } else {
      return false;
    }
  } catch (e) {
    console.error('Error verifying league password: ', e);
    throw e;
  }
};

export const getContentByType = async (contentType: string): Promise<ContentItem[]> => {
  try {
    const q = query(
      collection(db, 'content'),
      where('type', '==', contentType)
    );
    const querySnapshot = await getDocs(q);
    const items = querySnapshot.docs.map(doc => ({ 
      id: doc.id, 
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate() // Convert Firestore Timestamp to Date
    } as ContentItem));
    
    // Sort items by order if it exists, otherwise by createdAt
    return items.sort((a, b) => {
      if (a.order !== undefined && b.order !== undefined) {
        return a.order - b.order;
      } else if (a.order !== undefined) {
        return -1;
      } else if (b.order !== undefined) {
        return 1;
      } else {
        return b.createdAt.getTime() - a.createdAt.getTime();
      }
    });
  } catch (e) {
    console.error(`Error getting content with type ${contentType}: `, e);
    throw e;
  }
};

export const resetPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const voteContent = async (contentId: string, userId: string, voteType: 'up' | 'down' | null) => {
  const contentRef = doc(db, 'content', contentId);
  const votesRef = doc(db, 'contentVotes', contentId);

  try {
    await runTransaction(db, async (transaction) => {
      const votesDoc = await transaction.get(votesRef);
      
      if (!votesDoc.exists()) {
        // If the document doesn't exist, create it with initial values
        if (voteType) {
          transaction.set(votesRef, {
            upvotes: voteType === 'up' ? 1 : 0,
            downvotes: voteType === 'down' ? 1 : 0,
            userVotes: {
              [userId]: voteType
            }
          });
          transaction.update(contentRef, {
            [`${voteType}votes`]: increment(1),
          });
        }
      } else {
        // If the document exists, update it
        const currentVotes = votesDoc.data();
        const currentUserVote = currentVotes.userVotes[userId];

        if (currentUserVote === voteType) {
          // User is trying to vote the same way, so remove their vote
          transaction.update(votesRef, {
            [`${currentUserVote}votes`]: increment(-1),
            [`userVotes.${userId}`]: null,
          });
          transaction.update(contentRef, {
            [`${currentUserVote}votes`]: increment(-1),
          });
        } else {
          // User is changing their vote or voting for the first time
          if (currentUserVote) {
            // Remove the old vote
            transaction.update(votesRef, {
              [`${currentUserVote}votes`]: increment(-1),
            });
            transaction.update(contentRef, {
              [`${currentUserVote}votes`]: increment(-1),
            });
          }
          if (voteType) {
            // Add the new vote
            transaction.update(votesRef, {
              [`${voteType}votes`]: increment(1),
              [`userVotes.${userId}`]: voteType,
            });
            transaction.update(contentRef, {
              [`${voteType}votes`]: increment(1),
            });
          } else {
            // Just remove the user's vote
            transaction.update(votesRef, {
              [`userVotes.${userId}`]: null,
            });
          }
        }
      }
    });

    // Fetch and return the updated vote counts
    const updatedVotesDoc = await getDoc(votesRef);
    const updatedVotes = updatedVotesDoc.data() || { upvotes: 0, downvotes: 0, userVotes: {} };
    return {
      upvotes: updatedVotes.upvotes,
      downvotes: updatedVotes.downvotes,
      userVote: updatedVotes.userVotes[userId] || null,
    };
  } catch (error) {
    console.error('Error voting:', error);
    throw error;
  }
};
export const getContentVotes = async (contentId: string) => {
  try {
    const votesRef = doc(db, 'contentVotes', contentId);
    const votesDoc = await getDoc(votesRef);
    
    if (votesDoc.exists()) {
      const data = votesDoc.data();
      return {
        upvotes: data.upvotes || 0,
        downvotes: data.downvotes || 0,
        userVotes: data.userVotes || {}
      };
    } else {
      return {
        upvotes: 0,
        downvotes: 0,
        userVotes: {}
      };
    }
  } catch (error) {
    console.error('Error getting content votes:', error);
    throw error;
  }
};

// Create a secondary app
const secondaryApp = initializeApp(firebaseConfig, 'Secondary');
const secondaryAuth = getAuth(secondaryApp);
const db2 = getFirestore(secondaryApp);


export const createUser = async (email: string, password: string, role: 'user' | 'commissioner' | 'admin') => {
  try {
    // Create new user with secondary app
    const userCredential = await createUserWithEmailAndPassword(secondaryAuth, email, password);
    const user = userCredential.user;

    // Set user role in Firestore
    await setDoc(doc(db2, 'users', user.uid), {
      email: user.email,
      role: role
    });

    // Sign out from secondary app
    await signOut(secondaryAuth);

    // Return success
    return { success: true, uid: user.uid };
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateContent = async (id: string, contentData: Partial<ContentItem>): Promise<void> => {
  try {
    const contentRef = doc(db, 'content', id);
    
    // If there's a new image, upload it first
    if (contentData.image instanceof File) {
      const imageUrl = await uploadImage(contentData.image);
      contentData.image = imageUrl;
    }

    // Remove any undefined fields from contentData
    const cleanedData = Object.fromEntries(
      Object.entries(contentData).filter(([_, value]) => value !== undefined)
    );

    await updateDoc(contentRef, cleanedData);
  } catch (error) {
    console.error('Error updating content:', error);
    throw error;
  }
};



export const fetchYahooLeagueData = async (yahooToken: string | null, refreshYahooToken: () => Promise<string>): Promise<YahooLeague[]> => {
  try {
    console.log('Starting fetchYahooLeagueData');
    let validToken = yahooToken;
    if (!validToken) {
      console.log('No token provided, refreshing...');
      validToken = await refreshYahooToken();
    }

    if (!validToken) {
      throw new Error('Failed to obtain Yahoo token');
    }

    // Create season handler instance at the beginning
    const seasonHandler = createYahooSeasonHandler(validToken, refreshYahooToken);

    const userLeaguesUrl = `https://fantasysports.yahooapis.com/fantasy/v2/users;use_login=1/games;game_keys=nfl/leagues`;
    console.log('Fetching user leagues from:', userLeaguesUrl);
    
    const userLeaguesResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
      url: userLeaguesUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${validToken}`
      }
    });

    console.log('Raw Yahoo API response:', userLeaguesResponse.data);

    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: "@_",
    });
    const parsedData = parser.parse(userLeaguesResponse.data);
    console.log('Parsed league data:', JSON.stringify(parsedData, null, 2));

    const leagues = parsedData.fantasy_content.users.user.games.game.leagues.league;
    const leaguesArray = Array.isArray(leagues) ? leagues : [leagues];

    const yahooLeagues = await Promise.all(leaguesArray.map(async (league: any) => {
      console.log('Processing league:', {
        league_key: league.league_key,
        name: league.name,
        season: league.season
      });

      const leagueTeamsUrl = `https://fantasysports.yahooapis.com/fantasy/v2/league/${league.league_key}/teams`;
      console.log('Fetching teams for league:', leagueTeamsUrl);
      
      const leagueTeamsResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url: leagueTeamsUrl,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${validToken}`
        }
      });

      const leagueTeamsData = parser.parse(leagueTeamsResponse.data);
      const teams = leagueTeamsData.fantasy_content.league.teams.team;
      const teamsArray = Array.isArray(teams) ? teams : [teams];

      const standingsUrl = `https://fantasysports.yahooapis.com/fantasy/v2/league/${league.league_key}/standings`;
      console.log('Fetching standings from:', standingsUrl);
      
      const standingsResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url: standingsUrl,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${validToken}`
        }
      });

      const standingsData = parser.parse(standingsResponse.data);
      const standings = standingsData.fantasy_content.league.standings.teams.team;
      const standingsArray = Array.isArray(standings) ? standings : [standings];

      standingsArray.sort((a: any, b: any) => 
        parseInt(a.team_standings.rank) - parseInt(b.team_standings.rank)
      );

      const champion = standingsArray[0];
      const lastPlace = standingsArray[standingsArray.length - 1];

      // Get metadata to check for previous season
      const metadataUrl = `https://fantasysports.yahooapis.com/fantasy/v2/league/${league.league_key}/metadata`;
      console.log('Fetching metadata for:', metadataUrl);
      
      const metadataResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url: metadataUrl,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${validToken}`
        }
      });

      const metadataData = parser.parse(metadataResponse.data);
      const renewString = metadataData?.fantasy_content?.league?.renew;
      console.log('Found renew string:', renewString);

      // Create team record function with proper typing
      const createTeamRecord = (team: any): SeasonRecord => ({
        name: team.name,
        team_key: team.team_key,
        rank: team.team_standings.rank,
        wins: team.team_standings.outcome_totals.wins,
        losses: team.team_standings.outcome_totals.losses,
        ties: team.team_standings.outcome_totals.ties || "0",
        points_for: team.team_points.total,
        season: league.season,
        topThree: standingsArray.slice(0, 3).map((t: any, index: number) => ({
          name: t.name,
          rank: index + 1,
          wins: parseInt(t.team_standings.outcome_totals.wins),
          losses: parseInt(t.team_standings.outcome_totals.losses),
          points: parseFloat(t.team_points.total)
        })),
        highestScorer: {
          name: standingsArray[0].name,
          points: parseFloat(standingsArray[0].team_points.total)
        },
        lastPlace: {
          name: lastPlace.name,
          wins: parseInt(lastPlace.team_standings.outcome_totals.wins),
          losses: parseInt(lastPlace.team_standings.outcome_totals.losses),
          points: parseFloat(lastPlace.team_points.total)
        }
      });

      const yahooLeague: YahooLeague = {
        league_key: league.league_key,
        league_id: league.league_id,
        name: league.name,
        url: league.url,
        logo_url: league.logo_url,
        draft_status: league.draft_status,
        num_teams: parseInt(league.num_teams),
        edit_key: league.edit_key,
        weekly_deadline: league.weekly_deadline,
        league_update_timestamp: league.league_update_timestamp,
        scoring_type: league.scoring_type,
        league_type: league.league_type,
        renew: renewString,
        renewed: league.renewed,
        iris_group_chat_id: league.iris_group_chat_id,
        short_invitation_url: league.short_invitation_url,
        allow_add_to_dl_extra_pos: league.allow_add_to_dl_extra_pos === '1',
        is_pro_league: league.is_pro_league === '1',
        is_cash_league: league.is_cash_league === '1',
        current_week: league.current_week,
        start_week: league.start_week,
        start_date: league.start_date,
        end_week: league.end_week,
        end_date: league.end_date,
        game_code: league.game_code,
        season: league.season,
        is_plus_league: league.is_plus_league === '1',
        felo_tier: league.felo_tier,
        teams: teamsArray.map((team: any) => ({
          team_key: team.team_key,
          team_id: team.team_id,
          name: team.name,
          is_owned_by_current_login: team.is_owned_by_current_login === '1',
          url: team.url,
          team_logos: team.team_logos,
          waiver_priority: parseInt(team.waiver_priority),
          number_of_moves: parseInt(team.number_of_moves),
          number_of_trades: parseInt(team.number_of_trades),
          roster_adds: team.roster_adds,
          league_scoring_type: team.league_scoring_type,
          has_draft_grade: team.has_draft_grade === '1',
          managers: team.managers
        })),
        champion: createTeamRecord(champion),
        lastPlace: createTeamRecord(lastPlace)
      };

      // Handle previous season data
      if (renewString) {
        try {
          const [gameKey, leagueId] = renewString.split('_');
          if (gameKey && leagueId) {
            const previousLeagueKey = `${gameKey}.l.${leagueId}`;
            console.log('Previous season league key:', previousLeagueKey);
            
            const previousSeasonStandings = await seasonHandler.getLeagueStandings(previousLeagueKey);
            const previousSeasonMetadata = await seasonHandler.getLeagueMetadata(previousLeagueKey);
            
            if (previousSeasonStandings && previousSeasonMetadata) {
              const previousStandingsArray = Array.isArray(previousSeasonStandings.standings.teams.team) 
                ? previousSeasonStandings.standings.teams.team 
                : [previousSeasonStandings.standings.teams.team];
              
              previousStandingsArray.sort((a: any, b: any) => 
                parseInt(a.team_standings.rank) - parseInt(b.team_standings.rank)
              );

              const previousChampion = previousStandingsArray[0];
              const previousLastPlace = previousStandingsArray[previousStandingsArray.length - 1];

              yahooLeague.previous_season = {
                season: previousSeasonMetadata.season,
                champion: createTeamRecord(previousChampion),
                lastPlace: createTeamRecord(previousLastPlace),
                league_key: previousLeagueKey
              };
            }
          }
        } catch (error) {
          console.error('Error processing previous season:', error);
        }
      }

      console.log('Constructed Yahoo league with previous season:', yahooLeague);
      return yahooLeague;
    }));

    console.log('Storing Yahoo leagues in Firebase:', yahooLeagues);
    for (const yahooLeague of yahooLeagues) {
      await storeYahooLeagueData(yahooLeague);
    }

    return yahooLeagues;
  } catch (error) {
    console.error('Error in fetchYahooLeagueData:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      console.error('Response status:', error.response?.status);
    }
    throw error;
  }
};

const storeYahooLeagueData = async (yahooLeague: YahooLeague): Promise<void> => {
  try {
    const docId = yahooLeague.league_id.toString();
    const yahooLeagueRef = doc(db, 'yahooLeagues', docId);
    
    // Log the data before cleaning
    console.log('Original Yahoo league data:', JSON.stringify(yahooLeague, null, 2));
    
    // Remove circular references and function properties
    const leagueData = JSON.parse(JSON.stringify(yahooLeague));
    
    // Log the cleaned data
    console.log('Cleaned Yahoo league data:', JSON.stringify(leagueData, null, 2));
    
    const dataToStore = {
      ...leagueData,
      lastUpdated: new Date()
    };

    await setDoc(yahooLeagueRef, dataToStore);
    console.log('Yahoo league data stored successfully');
    
    // Verify the stored data
    const storedData = await getDoc(yahooLeagueRef);
    console.log('Verified stored data:', JSON.stringify(storedData.data(), null, 2));
  } catch (error) {
    console.error('Error storing Yahoo league data:', error);
    throw error;
  }
};

export const getYahooLeagueData = async (yahooLeagueId: string, yahooToken: string, refreshYahooToken: () => Promise<string>) => {
  try {
    const response = await axios.get(`https://fantasysports.yahooapis.com/fantasy/v2/league/${yahooLeagueId}`, {
      headers: {
        Authorization: `Bearer ${yahooToken}`
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      // Token expired, refresh and try again
      const newToken = await refreshYahooToken();
      const retryResponse = await axios.get(`https://fantasysports.yahooapis.com/fantasy/v2/league/${yahooLeagueId}`, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      });
      return retryResponse.data;
    }
    throw error;
  }
};

function isYahooApiResponse(data: unknown): data is YahooApiResponse {
  return (
    typeof data === 'object' &&
    data !== null &&
    'fantasy_content' in data &&
    typeof (data as any).fantasy_content === 'object' &&
    'users' in (data as any).fantasy_content &&
    typeof (data as any).fantasy_content.users === 'object' &&
    'user' in (data as any).fantasy_content.users &&
    typeof (data as any).fantasy_content.users.user === 'object' &&
    'games' in (data as any).fantasy_content.users.user &&
    typeof (data as any).fantasy_content.users.user.games === 'object' &&
    'game' in (data as any).fantasy_content.users.user.games &&
    typeof (data as any).fantasy_content.users.user.games.game === 'object' &&
    'leagues' in (data as any).fantasy_content.users.user.games.game
  );
}


export const handleYahooCallback = async (code: string, state: string) => {
  // Verify state
  const savedState = localStorage.getItem('yahoo_oauth_state');
  if (state !== savedState) {
    throw new Error('Invalid state parameter');
  }

  // Exchange code for token
  const tokenResponse = await axios.post('https://api.login.yahoo.com/oauth2/get_token', {
    grant_type: 'authorization_code',
    code,
    redirect_uri: 'https://fantasyloser.com/yahoo-callback',
    client_id: process.env.REACT_APP_YAHOO_CLIENT_ID,
    client_secret: process.env.REACT_APP_YAHOO_CLIENT_SECRET
  });

  const { access_token, refresh_token, expires_in } = tokenResponse.data;

  // Store tokens in Firestore
  if (auth.currentUser) {
    await setDoc(doc(db, 'users', auth.currentUser.uid), {
      yahooToken: {
        token: access_token,
        refreshToken: refresh_token,
        expiresAt: Date.now() + expires_in * 1000
      }
    }, { merge: true });
  }

  return access_token;
};

export const updateYahooLeagueId = async (leagueId: string, yahooLeagueId: string): Promise<void> => {
  try {
    const leagueRef = doc(db, 'leagues', leagueId);
    await updateDoc(leagueRef, { yahooLeagueId });
    console.log('Yahoo league ID updated successfully');
  } catch (e) {
    console.error('Error updating Yahoo league ID: ', e);
    throw e;
  }
};


export const addWeeklyChallenge = async (challenge: Omit<WeeklyChallenge, 'id' | 'createdAt' | 'updatedAt'>): Promise<string> => {
  try {
    let imageUrl: string | null = null;
    
    // Handle image upload if present
    if (challenge.image instanceof File) {
      const storageRef = storeRef(storage, `weekly-challenges/${Date.now()}_${challenge.image.name}`);
      await uploadBytes(storageRef, challenge.image);
      imageUrl = await getDownloadURL(storageRef);
    } else if (typeof challenge.image === 'string') {
      imageUrl = challenge.image;
    }

    const { image, ...challengeData } = challenge;
    const timestamp = new Date();

    // Create the main challenge document first
    const docRef = await addDoc(collection(db, 'weeklyChallenge'), {
      ...challengeData,
      image: imageUrl,
      createdAt: timestamp,
      updatedAt: timestamp
    });

    // Update or create the current document with reference to the original
    const currentRef = doc(db, 'weeklyChallenge', 'current');
    await setDoc(currentRef, {
      ...challengeData,
      image: imageUrl,
      createdAt: timestamp,
      updatedAt: timestamp,
      originalId: docRef.id
    });

    return docRef.id;
  } catch (error) {
    console.error('Error adding weekly challenge:', error);
    throw error;
  }
};


export const updateWeeklyChallenge = async (
  id: string, 
  data: Partial<Omit<WeeklyChallenge, 'id' | 'createdAt' | 'updatedAt'>>
): Promise<void> => {
  try {
    let imageUrl: string | null = null;
    let shouldUpdateImage = false;
    
    if (data.image instanceof File) {
      const storageRef = storeRef(storage, `weekly-challenges/${Date.now()}_${data.image.name}`);
      await uploadBytes(storageRef, data.image);
      imageUrl = await getDownloadURL(storageRef);
      shouldUpdateImage = true;
    } else if (typeof data.image === 'string') {
      imageUrl = data.image;
      shouldUpdateImage = true;
    } else if (data.image === null) {
      imageUrl = null;
      shouldUpdateImage = true;
    }

    const { image, ...restData } = data;
    const timestamp = new Date();
    
    const updateData: Record<string, any> = {
      ...restData,
      updatedAt: timestamp
    };

    if (shouldUpdateImage) {
      updateData.image = imageUrl;
    }

    // Update the challenge in the main collection
    const challengeRef = doc(db, 'weeklyChallenge', id);
    await updateDoc(challengeRef, updateData);

    // Check if this is the current challenge and update it as well
    const currentRef = doc(db, 'weeklyChallenge', 'current');
    const currentSnap = await getDoc(currentRef);
    if (currentSnap.exists() && currentSnap.data().originalId === id) {
      await updateDoc(currentRef, updateData);
    }
  } catch (error) {
    console.error('Error updating weekly challenge:', error);
    throw error;
  }
};

export const deleteWeeklyChallenge = async (id: string): Promise<void> => {
  try {
    // Get the current challenge first
    const currentRef = doc(db, 'weeklyChallenge', 'current');
    const currentSnap = await getDoc(currentRef);
    const currentData = currentSnap.exists() ? currentSnap.data() : null;

    // Delete the challenge document
    await deleteDoc(doc(db, 'weeklyChallenge', id));

    // If this was the current challenge, delete the current reference
    if (currentData && currentData.originalId === id) {
      await deleteDoc(currentRef);
    }
  } catch (error) {
    console.error('Error deleting weekly challenge:', error);
    throw error;
  }
};

export const getWeeklyChallenges = async (): Promise<WeeklyChallenge[]> => {
  try {
    // First get the current challenge
    const currentRef = doc(db, 'weeklyChallenge', 'current');
    const currentSnap = await getDoc(currentRef);
    const currentChallenge = currentSnap.exists() ? currentSnap.data() : null;

    // Get all challenges
    const querySnapshot = await getDocs(
      query(
        collection(db, 'weeklyChallenge'),
        orderBy('createdAt', 'desc')
      )
    );

    // Filter out the 'current' document and any challenge with the same name as current
    const challenges = querySnapshot.docs
      .filter(doc => {
        if (doc.id === 'current') return false;
        if (currentChallenge && doc.data().name === currentChallenge.name) return false;
        return true;
      })
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate(),
        status: currentChallenge && doc.id === currentChallenge.originalId ? 'active' : 'completed'
      })) as WeeklyChallenge[];

    // Add current challenge to the beginning if it exists
    if (currentChallenge) {
      challenges.unshift({
        ...currentChallenge,
        id: currentChallenge.originalId,
        status: 'active'
      } as WeeklyChallenge);
    }

    return challenges;
  } catch (error) {
    console.error('Error getting weekly challenges:', error);
    throw error;
  }
};

export const getWeeklyChallengeById = async (id: string): Promise<WeeklyChallenge | null> => {
  try {
    const docRef = doc(db, 'weeklyChallenge', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        id: docSnap.id,
        ...data,
        createdAt: data.createdAt?.toDate(),
        updatedAt: data.updatedAt?.toDate()
      } as WeeklyChallenge;
    }
    return null;
  } catch (error) {
    console.error('Error getting weekly challenge:', error);
    throw error;
  }
};

export const fetchYahooLeagueStandings = async (leagueKey: string, yahooToken: string, refreshYahooToken: () => Promise<string>): Promise<any> => {
  try {
    let token = yahooToken;
    if (!token) {
      token = await refreshYahooToken();
    }

    const standingsUrl = `https://fantasysports.yahooapis.com/fantasy/v2/league/${leagueKey}/standings`;
    const standingsResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
      url: standingsUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: "@_",
    });
    const parsedData = parser.parse(standingsResponse.data);

    const teams = parsedData.fantasy_content.league.standings.teams.team;
    return Array.isArray(teams) ? teams : [teams];
  } catch (error) {
    console.error('Error fetching Yahoo league standings:', error);
    throw error;
  }
};

export const updatePunishmentOrder = async (punishmentId: string, newOrder: number): Promise<void> => {
  try {
    const punishmentRef = doc(db, 'content', punishmentId);
    await updateDoc(punishmentRef, { order: newOrder });
  } catch (error) {
    console.error('Error updating punishment order:', error);
    throw error;
  }
};


const parseRenewString = (renewString: string): PreviousSeasonKeys | null => {
  try {
    const [gameKey, leagueId] = renewString.split('_');
    if (!gameKey || !leagueId) {
      console.error('Invalid renew string format:', renewString);
      return null;
    }
    return { gameKey, leagueId };
  } catch (error) {
    console.error('Error parsing renew string:', error);
    return null;
  }
};


export const fetchPreviousSeasonData = async (
  yahooToken: string, 
  leagueKey: string, 
  refreshYahooToken: () => Promise<string>
): Promise<any> => {
  try {
    let token = yahooToken;
    if (!token) {
      token = await refreshYahooToken();
      if (!token) {
        throw new Error('Failed to obtain Yahoo token');
      }
    }

    // First, get the current league's metadata to find the renewal key
    const leagueMetadataUrl = `https://fantasysports.yahooapis.com/fantasy/v2/league/${leagueKey}/metadata`;
    console.log('Fetching metadata from:', leagueMetadataUrl);
    
    const metadataResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
      url: leagueMetadataUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: "@_",
    });
    
    const metadataData = parser.parse(metadataResponse.data);
    const renewString = metadataData?.fantasy_content?.league?.renew;

    if (!renewString) {
      console.log('No renewal information found for this league');
      return null;
    }

    console.log('Found renew string:', renewString);
    const previousSeasonKeys = parseRenewString(renewString);
    if (!previousSeasonKeys) {
      console.log('Unable to parse renewal information:', renewString);
      return null;
    }

    // Construct the previous season's league key
    const previousLeagueKey = `${previousSeasonKeys.gameKey}.l.${previousSeasonKeys.leagueId}`;
    console.log('Previous season league key:', previousLeagueKey);

    // Use the previous league key to fetch standings
    const standingsUrl = `https://fantasysports.yahooapis.com/fantasy/v2/league/${previousLeagueKey}/standings`;
    console.log('Fetching standings from:', standingsUrl);
    
    const standingsResponse = await axios.post('/.netlify/functions/yahoo-api-proxy', {
      url: standingsUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const standingsData = parser.parse(standingsResponse.data);
    
    if (!standingsData.fantasy_content?.league) {
      console.log('No standings data available for the previous season');
      return null;
    }

    const standings = standingsData.fantasy_content.league.standings.teams.team;
    const standingsArray = Array.isArray(standings) ? standings : [standings];
    const previousSeasonYear = standingsData.fantasy_content.league.season;

    // Sort teams by rank
    standingsArray.sort((a: any, b: any) => 
      parseInt(a.team_standings.rank) - parseInt(b.team_standings.rank)
    );

    const champion = standingsArray[0];
    const lastPlace = standingsArray[standingsArray.length - 1];
    const highestScorer = standingsArray.reduce((prev: any, current: any) => {
      const prevPoints = parseFloat(prev.team_points.total);
      const currentPoints = parseFloat(current.team_points.total);
      return currentPoints > prevPoints ? current : prev;
    }, standingsArray[0]);

    // Helper function to create a team record
    const createTeamRecord = (team: any, season: string) => ({
      name: team.name,
      team_key: team.team_key,
      rank: team.team_standings.rank,
      wins: team.team_standings.outcome_totals.wins,
      losses: team.team_standings.outcome_totals.losses,
      ties: team.team_standings.outcome_totals.ties || "0",
      points_for: team.team_points.total,
      season,
      topThree: standingsArray.slice(0, 3).map((t: any, index: number) => ({
        name: t.name,
        rank: index + 1,
        wins: parseInt(t.team_standings.outcome_totals.wins),
        losses: parseInt(t.team_standings.outcome_totals.losses),
        points: parseFloat(t.team_points.total)
      })),
      highestScorer: {
        name: highestScorer.name,
        points: parseFloat(highestScorer.team_points.total)
      },
      lastPlace: {
        name: lastPlace.name,
        wins: parseInt(lastPlace.team_standings.outcome_totals.wins),
        losses: parseInt(lastPlace.team_standings.outcome_totals.losses),
        points: parseFloat(lastPlace.team_points.total)
      }
    });

    const result = {
      season: previousSeasonYear,
      champion: createTeamRecord(champion, previousSeasonYear),
      lastPlace: createTeamRecord(lastPlace, previousSeasonYear),
      league_key: previousLeagueKey
    };

    console.log('Successfully retrieved previous season data:', result);
    return result;

  } catch (error) {
    console.error('Error fetching previous season data:', error);
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        console.log('Previous season data not found');
        return null;
      }
      console.error('Yahoo API response:', error.response?.data);
    }
    throw error;
  }
};

export const deleteLeague = async (leagueId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'leagues', leagueId));
  } catch (error) {
    console.error('Error deleting league:', error);
    throw error;
  }
};