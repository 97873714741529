// ChallengeDetailPage.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getWeeklyChallengeById } from '../services/firebase';
import { WeeklyChallenge } from '../components/WeeklyChallengeForm';
import { useTheme } from '../contexts/ThemeContext';
import { Clock, Share2 } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';

const ChallengeDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [challenge, setChallenge] = useState<WeeklyChallenge | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    const fetchChallenge = async () => {
      if (id) {
        try {
          const fetchedChallenge = await getWeeklyChallengeById(id);
          setChallenge(fetchedChallenge);
        } catch (error) {
          console.error('Error fetching challenge:', error);
        }
        setIsLoading(false);
      }
    };

    fetchChallenge();
  }, [id]);

  if (isLoading) {
    return <StyledLoadingSpinner/>;
  }

  if (!challenge) {
    return <div>Challenge not found</div>;
  }

  return (
    <div className={`min-h-screen flex flex-col ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <div className="space-y-8">
            {/* Header Section with Status and Week */}
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <span className="text-2xl font-bold text-gray-600">Week {challenge.week}</span>
                {challenge.status === 'active' && (
                  <span className="bg-[#18f7b1] text-black px-3 py-1 rounded-full text-sm font-medium">
                    Active Challenge
                  </span>
                )}
              </div>
              <div className="text-sm text-gray-500">
                {challenge.createdAt && (
                  <div>Created: {new Date(challenge.createdAt).toLocaleDateString()}</div>
                )}
              </div>
            </div>

            {/* Challenge Image */}
            {typeof challenge.image === 'string' && challenge.image && (
              <div className="w-full rounded-xl overflow-hidden shadow-lg">
                <img
                  src={challenge.image}
                  alt={challenge.name}
                  className="w-full h-auto object-cover"
                />
              </div>
            )}

            {/* Challenge Title and Description */}
            <div className="space-y-6">
              <h1 className="text-4xl font-bold">{challenge.name}</h1>
              
              <div className="bg-black text-white p-6 rounded-xl">
                <h2 className="text-2xl font-bold mb-4 text-[#18f7b1] uppercase">The Challenge</h2>
                <div 
                  className="text-lg whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: challenge.description.replace(/\n/g, '<br />') }}
                />

                {/* Position Tags */}
                <div className="mt-6 flex flex-wrap gap-2">
                  {challenge.positions.map((position) => (
                    <span
                      key={position}
                      className="bg-[#18f7b1] text-black px-3 py-1 rounded-full text-sm font-medium"
                    >
                      {position}
                    </span>
                  ))}
                </div>
              </div>

              <div className="bg-[#18f7b1] p-6 rounded-xl">
                <h2 className="text-2xl font-bold mb-4 uppercase">The Stakes</h2>
                <div 
                  className="text-lg whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: challenge.stakes.replace(/\n/g, '<br />') }}
                />
              </div>

              {/* Special Instructions for Total Yards Challenge */}
              {challenge.positions.includes('TOTAL_YARDS') && (
                <div className="bg-gray-100 p-6 rounded-xl">
                  <h3 className="text-xl font-bold mb-2">📊 Total Yards Tracking</h3>
                  <p className="text-lg">
                    Add up all passing, rushing, and receiving yards from your starting lineup. 
                    Every yard counts in this challenge!
                  </p>
                </div>
              )}

              {/* Position Combination Instructions */}
              {(challenge.positions.includes('RB1+RB2') || challenge.positions.includes('WR1+WR2')) && (
                <div className="bg-gray-100 p-6 rounded-xl">
                  <h3 className="text-xl font-bold mb-2">👥 Combined Position Rules</h3>
                  {challenge.positions.includes('RB1+RB2') && (
                    <p className="text-lg mb-2">
                      • RB1 + RB2: Combine the total yards from both starting running backs
                    </p>
                  )}
                  {challenge.positions.includes('WR1+WR2') && (
                    <p className="text-lg">
                      • WR1 + WR2: Combine the total yards from both starting wide receivers
                    </p>
                  )}
                </div>
              )}

              {/* Share Button */}
              <div className="flex justify-center mt-8">
                <button 
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                  }}
                  className="bg-black text-white px-6 py-3 rounded-full font-bold flex items-center hover:bg-gray-800 transition-colors duration-200"
                >
                  <Share2 className="mr-2" />
                  Share Challenge
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ChallengeDetailPage;