// src/components/leaguePages/Challenges.tsx

import React from 'react';
import { League } from '../../services/firebase';
import WeeklyChallenge from '../WeeklyChallengeDisplay';
import { useYahooAuth } from '../../contexts/YahooAuthContext';
import { Link } from 'react-router-dom';

interface ChallengesProps {
  league: League;
}

const Challenges: React.FC<ChallengesProps> = ({ league }) => {
  const { yahooToken, refreshYahooToken, yahooIsAuthenticated } = useYahooAuth();

  const renderChallengeContent = () => {
    if (!yahooIsAuthenticated) {
      return (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
          <p className="font-bold">Yahoo Account Not Connected</p>
          <p>Please connect a Yahoo account to import your teams and participate in the weekly challenge.</p>
          <Link to={`/league/${league.id}`} className="text-blue-500 hover:underline mt-2 inline-block">
            Go to league settings to connect
          </Link>
        </div>
      );
    }

    if (!league.yahooLeague || !league.yahooLeague.teams || league.yahooLeague.teams.length === 0) {
      return (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
          <p className="font-bold">No Teams Found</p>
          <p>Your Yahoo account is connected, but no teams were found for this league. Please make sure you've selected the correct Yahoo league in your league settings.</p>
          <Link to={`/league/${league.id}`} className="text-blue-500 hover:underline mt-2 inline-block">
            Go to league settings
          </Link>
        </div>
      );
    }

    return (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <WeeklyChallenge 
          yahooLeague={league.yahooLeague} 
          yahooToken={yahooToken!}
          refreshYahooToken={refreshYahooToken}
        />
      </div>
    );
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold mb-4">Challenges</h1>
      
      {renderChallengeContent()}
      
      {/* Add more challenge-related content here */}
    </div>
  );
};

export default Challenges;