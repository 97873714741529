import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWeeklyChallenges } from '../services/firebase';
import { WeeklyChallenge } from '../components/WeeklyChallengeForm';
import Footer from '../components/Footer';
import { Search, Grid, List } from 'lucide-react';
import Navbar from '../components/Navbar';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';

const ViewToggle: React.FC<{
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
}> = ({ viewMode, setViewMode }) => (
  <div className="flex items-center gap-2 bg-[#1A1A1A] rounded-lg p-1">
    <button
      onClick={() => setViewMode('grid')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'grid' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="grid grid-cols-2 gap-0.5 w-5 h-5">
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
    <button
      onClick={() => setViewMode('list')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'list' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="flex flex-col gap-0.5 w-5 h-5">
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
  </div>
);

const WeeklyChallengesPage: React.FC = () => {
  const [challenges, setChallenges] = useState<WeeklyChallenge[]>([]);
  const [filteredChallenges, setFilteredChallenges] = useState<WeeklyChallenge[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const fetchedChallenges = await getWeeklyChallenges();
        setChallenges(fetchedChallenges);
        setFilteredChallenges(fetchedChallenges);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching challenges:', error);
        setIsLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  useEffect(() => {
    const filtered = challenges.filter(challenge =>
      challenge.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChallenges(filtered);
  }, [searchTerm, challenges]);

  const handleCardClick = (challenge: WeeklyChallenge) => {
    navigate(`/challenges/${challenge.id}`);
  };

  const renderGridView = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {filteredChallenges.map((challenge) => (
        <div 
          key={challenge.id} 
          className="relative aspect-[9/16] rounded-lg overflow-hidden cursor-pointer
                    transform transition-transform duration-300 hover:scale-[1.02]
                    border border-[#18f7b1]"
          onClick={() => handleCardClick(challenge)}
        >
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${challenge.image || '/placeholder-image.jpg'})`,
            }}
          />
          
          <div className="absolute inset-0 bg-gradient-to-t 
                        from-black via-black/50 to-transparent
                        from-[0%] via-[40%] to-[100%]" />

          {challenge.status === 'active' && (
            <div className="absolute top-4 left-4 bg-[#18f7b1] text-black px-3 py-1.5 rounded-md font-bold">
              Active
            </div>
          )}

          <div className="absolute bottom-6 left-4 right-4 flex justify-between items-end">
            <div className="space-y-2">
              <div className="text-white/80 text-xl font-antonio">
                Week {challenge.week}
              </div>
              <h3 className="text-white text-4xl font-bold font-antonio leading-tight">
                {challenge.name}
              </h3>
            </div>
            
            <div className="rounded-md p-2 flex-shrink-0">
              <img 
                src={require("../assets/FL_Logo_FL_Icon_Primary_2.png")} 
                alt="FL Logo" 
                className="w-14 h-14"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderListView = () => (
    <div className="space-y-4">
      {filteredChallenges.map((challenge) => (
        <div
          key={challenge.id}
          onClick={() => handleCardClick(challenge)}
          className="relative bg-[#1A1A1A] rounded-lg overflow-hidden cursor-pointer
                    hover:shadow-lg transition-all duration-300 border border-[#18f7b1]"
        >
          <div className="flex items-center p-6 gap-8">
            {/* Week Number */}
            <div className="flex-shrink-0 w-24 text-center">
              <div className="text-white/60 text-sm">Week</div>
              <div className="text-2xl font-antonio text-[#18f7b1] font-bold">
                {challenge.week}
              </div>
            </div>
  
            {/* Title and Description */}
            <div className="flex-grow min-w-0">
              <h3 className="text-3xl font-antonio text-white mb-2 truncate">
                {challenge.name}
              </h3>
              <p className="text-white/80 text-base line-clamp-2">
                {challenge.description}
              </p>
            </div>
  
            {/* Status Badge */}
            {challenge.status === 'active' && (
              <div className="flex-shrink-0">
                <span className="bg-[#18f7b1] text-black px-4 py-2 rounded-full font-bold">
                  Active
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  if (isLoading) {
    return <StyledLoadingSpinner/>;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <div className="bg-[#1A1A1A] py-8 w-full">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto space-y-8">
            <h1 className="text-6xl sm:text-6xl md:text-8xl font-antonio text-white text-center">
              Weekly Challenges
            </h1>

            <h2 className="text-3xl font-antonio text-white text-center">
              Take On Our Weekly Fantasy Challenges
            </h2>

            <div className="relative">
              <input
                type="text"
                placeholder="Search Challenges..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-black/50 text-white pl-12 pr-4 py-3 rounded-lg
                         border border-white/10 focus:border-[#18f7b1] transition-colors
                         placeholder:text-white/50 font-antonio"
              />
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50" size={20} />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            <div className="flex justify-end items-center mb-6">
              <ViewToggle viewMode={viewMode} setViewMode={setViewMode} />
            </div>

            {viewMode === 'grid' ? renderGridView() : renderListView()}

            {filteredChallenges.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-500 text-lg">
                  No challenges found matching your search.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default WeeklyChallengesPage;