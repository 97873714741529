import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContentByType, updatePunishmentOrder } from '../services/firebase';
import { ContentItem } from '../types';
import { useAuth } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import { ArrowUp, ArrowDown, Search } from 'lucide-react';
import Navbar from '../components/Navbar';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';

interface FLRatingOption {
  value: string;
  label: string;
  level: number;
}

// Constants
const FL_RATING_OPTIONS: FLRatingOption[] = [
  { value: 'Pee Wee (L1)', label: 'Pee Wee (L1)', level: 1 },
  { value: 'Two Hand Touch (L2)', label: 'Two Hand Touch (L2)', level: 2 },
  { value: 'Full Contact (L3)', label: 'Full Contact (L3)', level: 3 },
  { value: 'All Pro Elite (L4)', label: 'All Pro Elite (L4)', level: 4 },
  { value: 'No Mercy (L5)', label: 'No Mercy (L5)', level: 5 },
];

// Helper Functions
const extractFLRatingLevel = (rating: string): number => {
  const match = rating.match(/Level\s*(\d+)/i);
  return match ? parseInt(match[1], 10) : 0;
};

// Components
interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  label: string;
}

const Slider: React.FC<SliderProps> = ({ value, onChange, min, max, label }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  
  const calculateValue = (clientX: number): number => {
    if (!sliderRef.current) return value;
    
    const rect = sliderRef.current.getBoundingClientRect();
    const x = clientX - rect.left;
    const percentage = Math.max(0, Math.min(1, x / rect.width));
    const newValue = Math.round(min + percentage * (max - min));
    
    return Math.max(min, Math.min(max, newValue));
  };

  const handleMove = (clientX: number) => {
    const newValue = calculateValue(clientX);
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.clientX);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging && e.touches[0]) {
        e.preventDefault();
        handleMove(e.touches[0].clientX);
      }
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, onChange, value, min, max]);

  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className="w-full">
      <div className="flex justify-center items-center mb-2">
        <label className="text-white text-xl font-antonio mr-2">{label}</label>
        <span className="text-white text-xl font-antonio">[{value}]</span>
      </div>
      <div 
        ref={sliderRef}
        className="relative h-4 flex items-center cursor-pointer"
        onMouseDown={(e) => {
          e.preventDefault();
          setIsDragging(true);
          handleMove(e.clientX);
        }}
        onTouchStart={(e) => {
          setIsDragging(true);
          handleMove(e.touches[0].clientX);
        }}
        onClick={(e) => {
          if (!isDragging) {
            handleMove(e.clientX);
          }
        }}
      >
        <div className="absolute w-full h-1 bg-white rounded flex items-center">
          <div
            className="absolute h-full bg-[#18f7b1] rounded"
            style={{ width: `${percentage}%` }}
          />
          {Array.from({ length: max - min + 1 }, (_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 rounded-full bg-black"
              style={{ left: `${((i + min) / (max - min)) * 100}%` }}
            />
          ))}
        </div>
        <div
          className="absolute w-5 h-5 rounded-full pointer-events-none
                     border-[2.5px] border-[#18f7b1] bg-[#1A1A1A]"
          style={{ left: `calc(${percentage}% - 10px)` }}
        />
      </div>
    </div>
  );
};


const ViewToggle: React.FC<{
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
}> = ({ viewMode, setViewMode }) => (
  <div className="flex items-center gap-2 bg-[#1A1A1A] rounded-lg p-1">
    <button
      onClick={() => setViewMode('grid')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'grid' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="grid grid-cols-2 gap-0.5 w-5 h-5">
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
    <button
      onClick={() => setViewMode('list')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'list' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="flex flex-col gap-0.5 w-5 h-5">
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
  </div>
);

const ExplorePage: React.FC = () => {
  const [punishments, setPunishments] = useState<ContentItem[]>([]);
  const [filteredPunishments, setFilteredPunishments] = useState<ContentItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [embarrassmentLevel, setEmbarrassmentLevel] = useState(0);
  const [riskLevel, setRiskLevel] = useState(0);
  const [longTermEffects, setLongTermEffects] = useState(0);
  const [selectedFLRating, setSelectedFLRating] = useState<string>('');
  const [isReordering, setIsReordering] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const navigate = useNavigate();
  const { userRole } = useAuth();

  useEffect(() => {
    const fetchPunishments = async () => {
      try {
        const content = await getContentByType('punishment');
        setPunishments(content);
        setFilteredPunishments(content);
        
        const tags = Array.from(new Set(content.flatMap(item => item.tags || [])));
        setAvailableTags(tags);
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching punishments:', error);
        setIsLoading(false);
      }
    };

    fetchPunishments();
  }, []);

  useEffect(() => {
    const filtered = punishments.filter(item => {
      const selectedLevel = FL_RATING_OPTIONS.find(
        option => option.value === selectedFLRating
      )?.level || 0;
      
      const itemLevel = extractFLRatingLevel(item.flRating || '');
      
      return (
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedTags.length === 0 || selectedTags.every(tag => item.tags?.includes(tag))) &&
        (embarrassmentLevel === 0 || parseInt(item.embarrassmentLevel) >= embarrassmentLevel) &&
        (riskLevel === 0 || parseInt(item.riskLevel) >= riskLevel) &&
        (longTermEffects === 0 || parseInt(item.longTermEffects) >= longTermEffects) &&
        (selectedFLRating === '' || itemLevel === selectedLevel)
      );
    });
    setFilteredPunishments(filtered);
  }, [searchTerm, selectedTags, embarrassmentLevel, riskLevel, longTermEffects, selectedFLRating, punishments]);

  const handleCardClick = (item: ContentItem) => {
    navigate(`/punishments/${item.id}`);
  };

  const handleTagToggle = (tag: string) => {
    setSelectedTags(prev =>
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    );
  };

  const handleReorder = async (itemId: string, direction: 'up' | 'down') => {
    const currentIndex = filteredPunishments.findIndex(item => item.id === itemId);
    if (currentIndex === -1) return;
  
    const newPunishments = [...filteredPunishments];
    const item = newPunishments[currentIndex];
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
  
    if (newIndex < 0 || newIndex >= newPunishments.length) return;
  
    newPunishments.splice(currentIndex, 1);
    newPunishments.splice(newIndex, 0, item);
  
    const updatedPunishments = newPunishments.map((item, index) => ({
      ...item,
      order: index
    }));
  
    await Promise.all(updatedPunishments.map((item) => 
      updatePunishmentOrder(item.id, item.order)
    ));
  
    setFilteredPunishments(updatedPunishments);
  };

  const renderGridView = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {filteredPunishments.map((item, index) => (
        <div 
          key={item.id} 
          className="relative aspect-[9/16] rounded-lg overflow-hidden cursor-pointer
                    transform transition-transform duration-300 hover:scale-[1.02]
                    border border-[#18f7b1]"
          onClick={() => !isReordering && handleCardClick(item)}
        >
          {isReordering && userRole === 'admin' && (
            <div className="absolute top-4 right-4 z-20 flex gap-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleReorder(item.id, 'up');
                }}
                className="p-1 bg-white rounded-full disabled:opacity-50"
                disabled={index === 0}
              >
                <ArrowUp size={20} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleReorder(item.id, 'down');
                }}
                className="p-1 bg-white rounded-full disabled:opacity-50"
                disabled={index === filteredPunishments.length - 1}
              >
                <ArrowDown size={20} />
              </button>
            </div>
          )}

          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${item.image || '/placeholder-image.jpg'})`,
            }}
          />
          
          <div className="absolute inset-0 bg-gradient-to-t 
                        from-black via-black/50 to-transparent
                        from-[0%] via-[40%] to-[100%]" />
          
          {item.flRating && (
            <div className="absolute top-4 left-4 bg-[#1A1A1A] rounded-md px-3 py-1.5 flex items-center gap-2">
              <img 
                src={require("../assets/FL_Logo_Loser_Icon_Grn.png")} 
                alt="FL Rating" 
                className="w-6 h-7"
              />
              <span className="text-white font-bold font-antonio text-xl">
                {(() => {
                  const match = item.flRating.match(/Level\s*(\d+)/i);
                  return match ? match[1] : '1';
                })()}
              </span>
            </div>
          )}

          <div className="absolute bottom-6 left-4 right-4 flex justify-between items-end">
            <h3 className="text-white text-4xl font-bold font-antonio leading-tight mr-4">
              {item.title}
            </h3>
            
            <div className="rounded-md p-2 flex-shrink-0">
              <img 
                src={require("../assets/FL_Logo_FL_Icon_Primary_2.png")} 
                alt="FL Logo" 
                className="w-14 h-14"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderListView = () => (
    <div className="space-y-4">
      {filteredPunishments.map((item, index) => (
        <div
          key={item.id}
          onClick={() => !isReordering && handleCardClick(item)}
          className="relative bg-white rounded-lg overflow-hidden cursor-pointer
                    hover:bg-[#FFC526] transition-all duration-300 border-b-2 border-black/50"
        >
          <div className="flex flex-col md:flex-row p-6 gap-6">
            {/* Left Section - Title and Rating */}
            <div className="md:w-1/4 flex-shrink-0">
              <div className="flex flex-col sm:flex-row gap-4 items-start">
              {item.flRating && (
                  <div className="flex items-center gap-2 bg-[#1A1A1A] rounded-lg px-4 py-1.5">
                    <img 
                      src={require("../assets/FL_Logo_Loser_Icon_Grn.png")} 
                      alt="FL Rating" 
                      className="w-6 h-6 "
                    />
                    <span className="text-white font-antonio text-xl mr-2">
                      {(() => {
                        const match = item.flRating.match(/Level\s*(\d+)/i);
                        return match ? `${match[1]}` : 'L1';
                      })()}
                    </span>
                  </div>
                )}
                <h3 className="text-2xl md:text-3xl font-antonio text-black font-bold">
                  {item.title}
                </h3>
                
                
              </div>
            </div>
  
            {/* Middle Section - Description */}
            <div className="flex-grow min-w-0">
              <p className="text-black/70 text-base line-clamp-2">
                {item.subtitle}
              </p>
            </div>
  
            {/* Right Section - Ratings */}
            <div className="flex flex-row md:flex-shrink-0 justify-between md:justify-end gap-4 md:gap-8 
                          pt-4 md:pt-0 mt-4 md:mt-0 border-t md:border-t-0 border-black/10">
              {/* Embarrassment Rating */}
              <div className="flex flex-col items-center">
                <div className="text-2xl font-bold font-antonio text-black mb-1">Embarrassment</div>
                <div className="text-xl font-antonio text-black">
                  {item.embarrassmentLevel}/5
                </div>
              </div>
  
              {/* Long Term Effects Rating */}
              <div className="flex flex-col items-center">
                <div className="text-2xl font-bold font-antonio text-black mb-1">Long Term</div>
                <div className="text-xl font-antonio text-black">
                  {item.longTermEffects}/5
                </div>
              </div>
  
              {/* Risk Level Rating */}
              <div className="flex flex-col items-center">
                <div className="text-2xl font-bold font-antonio text-black mb-1">Risk Level</div>
                <div className="text-xl font-antonio text-black">
                  {item.riskLevel}/5
                </div>
              </div>
            </div>
  
            {/* Reorder Controls */}
            {isReordering && userRole === 'admin' && (
              <div className="flex md:flex-col gap-2 justify-center md:ml-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(item.id, 'up');
                  }}
                  disabled={index === 0}
                  className="p-1 bg-black/5 rounded hover:bg-black/10 
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  aria-label="Move up"
                >
                  ↑
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(item.id, 'down');
                  }}
                  disabled={index === filteredPunishments.length - 1}
                  className="p-1 bg-black/5 rounded hover:bg-black/10 
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  aria-label="Move down"
                >
                  ↓
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
  
  if (isLoading) {
    return <StyledLoadingSpinner/>;
  }
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <div className="bg-[#1A1A1A] py-8 w-full">
        <div className="container mx-auto px-4 max-w-full">
        <div className="max-w-full sm:max-w-6xl mx-auto space-y-8">
          <h1 className="text-6xl sm:text-6xl md:text-8xl font-antonio text-white text-center">
  Explore Punishments
</h1>

            <h2 className="text-3xl font-antonio text-white text-center">
              Check Out Our Official Fantasy Loser Punishments
            </h2>
  
            
            <div className="flex flex-col sm:flex-row items-stretch gap-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search Punishments..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full bg-black/50 text-white pl-12 pr-4 py-3 rounded-lg
                           border border-white/10 focus:border-[#18f7b1] transition-colors
                           placeholder:text-white/50 font-antonio"
                />
                <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50" size={20} />
              </div>
  
              <div className="relative">
                <select
                  value={selectedFLRating || ""}
                  onChange={(e) => setSelectedFLRating(e.target.value)}
                  className="appearance-none bg-[#1A1A1A] text-white w-full px-4 py-3 rounded-lg
                            border border-white focus:border-[#18f7b1] transition-colors
                            font-antonio font-bold text-3xl pr-10
                            bg-[url('data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D%22white%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22%2F%3E%3C%2Fsvg%3E')]
                            bg-no-repeat bg-[right_0.5rem_center] focus:outline-none"
                >
                  <option value="">FL Rating</option>
                  {FL_RATING_OPTIONS.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                      className="bg-white text-[#1A1A1A] hover:bg-option-hover hover:text-option-hover rounded-lg"
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
  
  
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <Slider
                label="Embarrassment Level"
                value={embarrassmentLevel}
                onChange={setEmbarrassmentLevel}
                min={0}
                max={5}
              />
              <Slider
                label="Risk Level"
                value={riskLevel}
                onChange={setRiskLevel}
                min={0}
                max={5}
              />
              <Slider
                label="Long Term Effects"
                value={longTermEffects}
                onChange={setLongTermEffects}
                min={0}
                max={5}
              />
            </div>
  
            <div className="flex flex-wrap gap-2 overflow-x-auto">
              {availableTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => handleTagToggle(tag)}
                  className={`px-4 py-1.5 rounded-full text-sm transition-colors whitespace-nowrap
                    ${selectedTags.includes(tag)
                      ? 'bg-[#18f7b1] text-black'
                      : 'bg-black/50 text-white/80 hover:bg-black/70'
                    }`}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
  
      <div className="bg-white flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            <div className="flex justify-between items-center mb-6">
              {userRole === 'admin' && (
                <button
                  onClick={() => setIsReordering(!isReordering)}
                  className="bg-[#18f7b1] text-black px-4 py-2 rounded-lg hover:bg-[#0fd494] transition-colors"
                >
                  {isReordering ? 'Finish Reordering' : 'Reorder Punishments'}
                </button>
              )}
              
              <ViewToggle viewMode={viewMode} setViewMode={setViewMode} />
            </div>
  
            {viewMode === 'grid' ? renderGridView() : renderListView()}
  
            {filteredPunishments.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-500 text-lg">
                  No punishments found matching your criteria.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
  };
  
  export default ExplorePage;