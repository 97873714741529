import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { addWeeklyChallenge, getWeeklyChallenges, deleteWeeklyChallenge, updateWeeklyChallenge, getWeeklyChallengeById } from '../services/firebase';
import { Camera, Edit2, X } from 'lucide-react';
import WeeklyChallengeList from './WeeklyChallengeList';

interface WeeklyChallengeFormProps {
  userRole: string | null;
}

export interface WeeklyChallenge {
  id?: string;
  name: string;
  description: string;
  week: number;
  positions: string[];
  stakes: string;
  position?: string;
  image?: string | File | null;
  createdAt?: Date;
  updatedAt?: Date;
  status?: 'active' | 'completed';
  originalId?: string; // Used for the current challenge reference
}

export interface WeeklyChallengeUpdateData extends Partial<Omit<WeeklyChallenge, 'id' | 'createdAt' | 'updatedAt'>> {}

const WeeklyChallengeForm: React.FC<WeeklyChallengeFormProps> = ({ userRole }) => {
  const { theme } = useTheme();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [stakes, setStakes] = useState('');
  const [week, setWeek] = useState('');
  const [positions, setPositions] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [existingChallenges, setExistingChallenges] = useState<WeeklyChallenge[]>([]);
  const [selectedChallengeId, setSelectedChallengeId] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const nflPositions = [
    'QB', 
    'RB', 
    'WR', 
    'TE', 
    'K', 
    'DEF', 
    'RB1+RB2', 
    'WR1+WR2',
    'TOTAL_YARDS',
    'QB_RB_FORMULA'
  ];

  useEffect(() => {
    let mounted = true;
  
    const fetchChallenges = async () => {
      try {
        const challenges = await getWeeklyChallenges();
        if (mounted) {
          console.log('Setting challenges:', challenges.length);
          setExistingChallenges(challenges);
        }
      } catch (error) {
        if (mounted) {
          console.error('Error fetching challenges:', error);
          setError('Failed to load existing challenges');
        }
      }
    };
  
    fetchChallenges();
  
    return () => {
      mounted = false;
    };
  }, []);

  const fetchExistingChallenges = async () => {
    let mounted = true;
    try {
      const challenges = await getWeeklyChallenges();
      if (mounted) {
        setExistingChallenges(challenges);
      }
    } catch (error) {
      if (mounted) {
        console.error('Error fetching challenges:', error);
        setError('Failed to load existing challenges');
      }
    }
    return () => {
      mounted = false;
    };
  };

  const handlePositionChange = (position: string) => {
     
    setPositions(prevPositions => {
      if (position === 'TOTAL_YARDS' && !prevPositions.includes(position)) {
        setName("Weekly Total Yards Challenge");
        setDescription(
          "Compare your team's total offensive production! This challenge combines all " +
          "passing yards, rushing yards, and receiving yards from your starting lineup for the week. " +
          "The team with the highest total offensive yards wins!"
        );
      } if (position === 'QB_RB_FORMULA' && !prevPositions.includes(position)) {
        setName("QB-RB Efficiency Challenge");
        setDescription(
          "A complex challenge that measures the synergy between your QB and RBs! " +
          "The formula multiplies QB passing yards by RB receptions, then divides by " +
          "turnovers (interceptions and fumbles) plus 1. Higher scores are better!"
        );
        // Just return the formula position instead of additional positions
        return ['QB_RB_FORMULA'];
      }

      if (prevPositions.includes(position)) {
        return prevPositions.filter(p => p !== position);
      } else {
        if (position === 'RB1+RB2') {
          return [...prevPositions.filter(p => p !== 'RB'), position];
        } else if (position === 'WR1+WR2') {
          return [...prevPositions.filter(p => p !== 'WR'), position];
        } else if (position === 'RB') {
          return [...prevPositions.filter(p => p !== 'RB1+RB2'), position];
        } else if (position === 'WR') {
          return [...prevPositions.filter(p => p !== 'WR1+WR2'), position];
        }
        return [...prevPositions, position];
      }
    });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleExistingChallengeSelect = async (id: string) => {
    console.log('Selected challenge ID:', id); // Debug log
    
    if (!id) {
      console.log('No ID provided, resetting form'); // Debug log
      resetForm();
      return;
    }
  
    try {
      const challenge = await getWeeklyChallengeById(id);
      console.log('Fetched challenge:', challenge); // Debug log
      
      if (challenge) {
        setName(challenge.name);
        setDescription(challenge.description);
        setStakes(challenge.stakes);
        setWeek(challenge.week.toString());
        setPositions(challenge.positions);
        setImagePreview(typeof challenge.image === 'string' ? challenge.image : null);
        setSelectedChallengeId(id);
        setIsEditMode(true);
        
        console.log('Form updated with challenge data:', {
          name: challenge.name,
          week: challenge.week,
          image: challenge.image
        }); // Debug log
      } else {
        console.log('No challenge found for ID:', id); // Debug log
        setError('Challenge not found');
      }
    } catch (error) {
      console.error('Error loading challenge:', error);
      setError('Failed to load challenge details');
    }
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setStakes('');
    setWeek('');
    setPositions([]);
    setSelectedImage(null);
    setImagePreview(null);
    setSelectedChallengeId('');
    setIsEditMode(false);
    setError(null);
    setSuccess(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Return early if already loading
    if (isLoading) {
      return;
    }
  
    setError(null);
    setSuccess(null);
    setIsLoading(true);
  
    try {
      if (userRole !== 'admin') {
        throw new Error('Only admins can manage weekly challenges');
      }
  
      if (positions.length === 0) {
        throw new Error('Please select at least one position');
      }
  
      const weekNumber = parseInt(week);
      if (isNaN(weekNumber) || weekNumber < 1 || weekNumber > 17) {
        throw new Error('Please enter a valid week (1-17)');
      }
  
      const challengeData: Omit<WeeklyChallenge, 'id' | 'createdAt' | 'updatedAt'> = {
        name,
        description,
        week: weekNumber,
        positions,
        stakes,
        position: "",
        image: selectedImage
      };
  
      // Use let to track completion
      let completed = false;
  
      if (isEditMode && selectedChallengeId) {
        await updateWeeklyChallenge(selectedChallengeId, challengeData);
        completed = true;
        setSuccess('Challenge updated successfully');
      } else {
        await addWeeklyChallenge(challengeData);
        completed = true;
        setSuccess('Challenge created successfully');
      }
  
      // Only fetch and reset if the operation completed
      if (completed) {
        await fetchExistingChallenges();
        resetForm();
      }
    } catch (error) {
      console.error('Error saving challenge:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteChallenge = async (id: string) => {
    try {
      await deleteWeeklyChallenge(id);
      const updatedChallenges = await getWeeklyChallenges();
      setExistingChallenges(updatedChallenges);
      setSuccess('Challenge deleted successfully');
    } catch (error) {
      console.error('Error deleting challenge:', error);
      setError(error instanceof Error ? error.message : 'Failed to delete challenge');
    }
  };

  return (
    <div className="space-y-8">
{userRole === 'admin' && existingChallenges.length > 0 && (
  <WeeklyChallengeList
    challenges={existingChallenges}
    onChallengeSelect={handleExistingChallengeSelect}
    onChallengeDelete={handleDeleteChallenge}
    className="mb-8"
  />
)}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className={`${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} shadow-md rounded px-8 pt-6 pb-8`}>
          <h2 className="text-2xl font-bold mb-6">
            {isEditMode ? 'Edit Weekly Challenge' : 'Create Weekly Challenge'}
          </h2>

          {error && (
            <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}
          
          {success && (
            <div className="mb-6 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
              {success}
            </div>
          )}

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-bold mb-2" htmlFor="name">
                Challenge Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`w-full p-3 rounded border ${
                  theme === 'dark' 
                    ? 'bg-gray-700 text-white border-gray-600' 
                    : 'bg-white text-gray-800 border-gray-300'
                } focus:ring-2 focus:ring-[#18f7b1] focus:border-transparent`}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-bold mb-2" htmlFor="description">
                Challenge Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`w-full p-3 rounded border ${
                  theme === 'dark' 
                    ? 'bg-gray-700 text-white border-gray-600' 
                    : 'bg-white text-gray-800 border-gray-300'
                } focus:ring-2 focus:ring-[#18f7b1] focus:border-transparent`}
                rows={6}
                required
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </div>

            <div>
              <label className="block text-sm font-bold mb-2" htmlFor="stakes">
                Challenge Stakes
              </label>
              <textarea
                id="stakes"
                value={stakes}
                onChange={(e) => setStakes(e.target.value)}
                className={`w-full p-3 rounded border ${
                  theme === 'dark' 
                    ? 'bg-gray-700 text-white border-gray-600' 
                    : 'bg-white text-gray-800 border-gray-300'
                } focus:ring-2 focus:ring-[#18f7b1] focus:border-transparent`}
                rows={3}
                required
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </div>

            <div>
              <label className="block text-sm font-bold mb-2" htmlFor="week">
                Week
              </label>
              <input
                type="number"
                id="week"
                value={week}
                onChange={(e) => setWeek(e.target.value)}
                className={`w-full p-3 rounded border ${
                  theme === 'dark' 
                    ? 'bg-gray-700 text-white border-gray-600' 
                    : 'bg-white text-gray-800 border-gray-300'
                } focus:ring-2 focus:ring-[#18f7b1] focus:border-transparent`}
                min="1"
                max="17"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-bold mb-2">
                Challenge Image
              </label>
              <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
                <div className="space-y-2 text-center">
                  <div className="flex flex-col items-center">
                    {imagePreview ? (
                      <div className="relative">
                        <img
                          src={imagePreview}
                          alt="Challenge preview"
                          className="max-h-48 rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={handleRemoveImage}
                          className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                        >
                          <X size={16} />
                        </button>
                      </div>
                    ) : (
                      <Camera size={48} className="mx-auto text-gray-400" />
                    )}
                  </div>
                  
                  <div className="flex text-sm">
                    <label
                      htmlFor="image-upload"
                      className={`relative cursor-pointer rounded-md font-medium ${
                        theme === 'dark' ? 'text-[#18f7b1]' : 'text-blue-600'
                      }`}
                    >
                      <span>{imagePreview ? 'Change image' : 'Upload an image'}</span>
                      <input
                        id="image-upload"
                        ref={fileInputRef}
                        type="file"
                        className="sr-only"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-bold mb-2">
                Positions
              </label>
              <div className="flex flex-wrap gap-2">
                {nflPositions.map((position) => (
                  <label
                    key={position}
                    className={`
                      inline-flex items-center p-3 rounded cursor-pointer
                      ${positions.includes(position) 
                        ? 'bg-[#18f7b1] text-black' 
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}
                      ${position === 'TOTAL_YARDS' ? 'w-full' : ''}
                      transition-colors duration-200
                    `}
                  >
                    <input
                      type="checkbox"
                      value={position}
                      checked={positions.includes(position)}
                      onChange={() => handlePositionChange(position)}
                      className="form-checkbox h-5 w-5 mr-2"
                    />
                    <span>
                      {position}
                      {position === 'TOTAL_YARDS' && (
                        <span className="ml-2 text-sm opacity-75">
                          (Combines all passing, rushing, and receiving yards)
                        </span>
                      )}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex gap-4">
            <button
  type="submit"
  className={`
    flex-1 py-3 px-4 rounded font-bold
    ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
    ${theme === 'dark'
      ? 'bg-[#18f7b1] text-black hover:bg-[#0fd494]'
      : 'bg-blue-500 text-white hover:bg-blue-700'}
    transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#18f7b1]
  `}
  disabled={isLoading}
>
                {isLoading 
                  ? 'Saving...' 
                  : isEditMode 
                    ? 'Update Challenge' 
                    : 'Create Challenge'
                }
              </button>
              
              {isEditMode && (
                <button
                  type="button"
                  onClick={resetForm}
                  className={`
                    flex-1 py-3 px-4 rounded font-bold
                    ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
                    bg-gray-500 text-white hover:bg-gray-600 
                    transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                  `}
                  disabled={isLoading}
                >
                  Cancel Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WeeklyChallengeForm;