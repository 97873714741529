import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  FaDiscord, 
  FaInstagram, 
  FaTiktok, 
  FaTwitter, 
  FaYoutube,
  FaFacebookF,
  FaThreads,
  FaDiscord as FaDiscordAlt 
} from 'react-icons/fa6';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      const docRef = await addDoc(collection(db, 'newsletter'), {
        email: email,
        subscribedAt: new Date()
      });
      setMessage('Successfully subscribed!');
      setEmail('');
    } catch (error: any) {
      console.error('Newsletter subscription error:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <footer className="bg-[#1A1A1A] text-white py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left Column */}
          <div className="space-y-6">
            <div>
              <h2 className="font-antonio text-4xl mb-4">Join the conversation</h2>
              <p className="font-antonio font-light text-gray-400 max-w-md">
                Sign up for the Fantasy Loser newsletter to get weekly challenges, an inside look at punishments, and more as we continue to develop.
              </p>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-4 max-w-md">
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Subscribe to our newsletter"
                  className="w-full bg-transparent border-b border-white/20 py-2 pr-12 
                           placeholder-gray-500 focus:outline-none focus:border-[#18f7b1]
                           transition-colors font-antonio font-light"
                  required
                />
                <button 
                  type="submit" 
                  className="absolute right-0 top-1/2 -translate-y-1/2"
                  disabled={isLoading}
                >
                  →
                </button>
              </div>
              {message && (
                <p className={`text-sm ${
                  message.startsWith('Error') ? 'text-red-500' : 'text-[#18f7b1]'
                }`}>
                  {message}
                </p>
              )}
            </form>
          </div>

          {/* Right Column */}
          <div className="flex flex-col lg:items-end space-y-8">
            <nav className="space-x-8 lg:text-right">
              <Link to="/explore" className="font-antonio text-2xl hover:text-[#18f7b1] transition-colors">
                Punishments
              </Link>
              <Link to="/signup" className="font-antonio text-2xl hover:text-[#18f7b1] transition-colors">
                Sign Up
              </Link>
              <Link to="/login" className="font-antonio text-2xl hover:text-[#18f7b1] transition-colors">
                Login
              </Link>
            </nav>

            <div className="flex space-x-6">
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                <FaInstagram size={24} />
              </a>
              <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                <FaTwitter size={24} />
              </a>
              <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                <FaTiktok size={24} />
              </a>
              <a href="https://discord.gg/H6TADUTph2" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                <FaDiscordAlt size={24} />
              </a>
            </div>
          </div>
        </div>
                {/* Logo Section */}
                <div className="flex justify-center">
          <img 
            src={require('../assets/FL_Logo_Horizontal_Rev.png')}
            alt="Fantasy Loser"
            className="w-full max-w-[600px] h-auto"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;