import React, { useState, useEffect } from 'react';
import { doc, getDoc, runTransaction } from 'firebase/firestore';
import { db } from '../services/firebase';
import { YahooLeague } from '../types/yahoo';
import { Share2 } from 'lucide-react';
import axios from 'axios';
import { calculateQBRBFormula, QBRBFormulaStats } from '../components/weeklyChallenges/QBRB'


// Types and Interfaces
interface WeeklyChallengeProps {
  yahooLeague: YahooLeague;
  yahooToken: string;
  refreshYahooToken: () => Promise<string>;
}

interface WeeklyChallengeData {
  name: string;
  description: string;
  week: number;
  positions: string[];
  stakes: string;
}

interface QBStats {
  name: string;
  passingYards: number;
  interceptions: number;
}

interface RBStats {
  name: string;
  receptions: number;
  fumbles: number;
}

interface PositionStatsDetails {
  qbStats: QBStats;
  rbStats: RBStats[];
}

interface PositionStats {
  playerName: string;
  points: number;
  details?: PositionStatsDetails;
}

interface PositionResult {
  position: string;
  playerName: string;
  points: number;
  details?: {
    qbStats: QBStats;
    rbStats: RBStats[];
  };
}

interface TeamResult {
  name: string;
  totalPoints: number;
  positionResults: PositionResult[];
}

interface ChallengeResult {
  teams: TeamResult[];
  winner: string;
  loser: string;
  week: number;
  league_key: string;
  challengeTitle: string;
}

interface BaseElement extends Element {
  textContent: string | null;
}

interface XMLStatElement extends BaseElement {
  getElementsByTagName(tagName: string): HTMLCollectionOf<BaseElement>;
}

interface XMLPlayerStatsElement extends BaseElement {
  getElementsByTagName(tagName: string): HTMLCollectionOf<XMLStatElement>;
}

interface XMLPlayerElement extends BaseElement {
  getElementsByTagName(tagName: string): HTMLCollectionOf<XMLPlayerStatsElement>;
}

// Constants
const STAT_IDS = {
  PASSING_YARDS: "4",
  INTERCEPTIONS: "6",
  RUSHING_YARDS: "9",
  RECEPTIONS: "11",
  RECEIVING_YARDS: "12",
  FUMBLES: "18"
} as const;

// Helper Functions for XML Parsing
const getElementTextContent = (element: Element | null, tagName: string): string => {
  if (!element) return '';
  const collection = element.getElementsByTagName(tagName);
  return collection[0]?.textContent || '';
};

const getPlayerPosition = (playerElement: XMLPlayerElement): string => {
  const selectedPositionElement = playerElement.getElementsByTagName("selected_position")[0];
  return getElementTextContent(selectedPositionElement, "position");
};

const getPlayerName = (playerElement: XMLPlayerElement): string => {
  const nameElement = playerElement.getElementsByTagName("name")[0];
  return getElementTextContent(nameElement, "full") || "Unknown Player";
};

const getPlayerStats = (playerElement: XMLPlayerElement): Element[] => {
  const statsElement = playerElement.getElementsByTagName("player_stats")[0];
  const statsCollection = statsElement?.getElementsByTagName("stats")[0]?.getElementsByTagName("stat");
  return statsCollection ? Array.from(statsCollection) : [];
};

// Main Component
const WeeklyChallenge: React.FC<WeeklyChallengeProps> = ({ 
  yahooLeague, 
  yahooToken, 
  refreshYahooToken 
}): JSX.Element => {
  // State Management
  const [challenge, setChallenge] = useState<WeeklyChallengeData | null>(null);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [result, setResult] = useState<ChallengeResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentWeek, setCurrentWeek] = useState<number>(0);
  const [historicalResults, setHistoricalResults] = useState<ChallengeResult[]>([]);

  // Initial Data Fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const challengeDoc = await getDoc(doc(db, 'weeklyChallenge', 'current'));
        if (challengeDoc.exists()) {
          setChallenge(challengeDoc.data() as WeeklyChallengeData);
        }

        const currentWeekDoc = await getDoc(doc(db, 'currentWeek', 'nfl'));
        if (currentWeekDoc.exists()) {
          setCurrentWeek(currentWeekDoc.data().week);
        }

        const historicalResultsDoc = await getDoc(doc(db, 'weeklyChallengeResults', 'historical'));
        if (historicalResultsDoc.exists()) {
          const allResults = historicalResultsDoc.data().results || [];
          const leagueResults = allResults.filter((result: ChallengeResult) => 
            result.league_key === yahooLeague.league_key
          );
          setHistoricalResults(leagueResults);
        }

        const allTeamIds = yahooLeague.teams.map(team => team.team_id);
        setSelectedTeams(allTeamIds);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setError("Failed to load challenge data. Please try again later.");
      }
    };

    fetchInitialData();
  }, [yahooLeague]);

  // Stats Processing Function
  const getTeamStats = async (teamId: string, positions: string[], week: number) => {
    let token = yahooToken;
    if (!token) {
      token = await refreshYahooToken();
    }
  
    const rosterUrl = `https://fantasysports.yahooapis.com/fantasy/v2/team/${yahooLeague.league_key}.t.${teamId}/roster/players/stats;type=week;week=${week}`;

    console.log(`Fetching stats for team ${teamId} week ${week}`);
    
    try {
      const response = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url: rosterUrl,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      console.log('Raw XML response structure:', xmlDoc);
      const playerElements = Array.from(xmlDoc.getElementsByTagName("player"));
      
      const players = Array.from(xmlDoc.getElementsByTagName("player"));
      console.log(`Found ${players.length} total players`);

      players.forEach(player => {
        const name = getPlayerName(player);
        const pos = getPlayerPosition(player);
        console.log(`Player found: ${name} - Position: ${pos}`);
      });
      
      const positionStats: { [key: string]: PositionStats } = {};
  
      // Regular positions processing (QB, RB, WR, TE, K, DEF)
      const regularPositions = positions.filter(pos => 
        !['QB_RB_FORMULA', 'TOTAL_YARDS', 'RB1+RB2', 'WR1+WR2'].includes(pos)
      );
  
      regularPositions.forEach(position => {
        let bestScore = 0;
        let bestPlayerName = '';
  
        playerElements.forEach(playerElement => {
          const selectedPosition = getPlayerPosition(playerElement);
          if (!selectedPosition || selectedPosition === 'BN' || selectedPosition === 'IR') return;
  
          if (selectedPosition === position) {
            const playerName = getPlayerName(playerElement);
            const pointsElement = playerElement.getElementsByTagName("player_points")[0]
              ?.getElementsByTagName("total")[0];
            const points = parseFloat(pointsElement?.textContent || "0");
  
            if (points > bestScore) {
              bestScore = points;
              bestPlayerName = playerName;
            }
          }
        });
  
        positionStats[position] = {
          playerName: bestPlayerName || 'N/A',
          points: bestScore
        };
      });

       const startingPlayers = players.filter(player => {
        const position = getPlayerPosition(player);
        return position !== 'BN' && position !== 'IR';
      });
      
      console.log(`Found ${startingPlayers.length} starting players`);

      // Find starting QB
      const startingQB = startingPlayers.find(player => 
        getPlayerPosition(player) === 'QB'
      );
      
      // Find starting RBs
      const startingRBs = startingPlayers.filter(player => 
        getPlayerPosition(player) === 'RB'
      );
      
      console.log('Starting QB:', startingQB ? getPlayerName(startingQB) : 'None');
      console.log('Starting RBs:', startingRBs.map(rb => getPlayerName(rb)));
  
      // Handle QB_RB_FORMULA
     
if (positions.includes('QB_RB_FORMULA')) {
  console.log('Starting QB_RB_FORMULA processing...');
  
  const startingPlayers = players.filter(player => {
    const position = getPlayerPosition(player);
    const isStarting = position !== 'BN' && position !== 'IR';
    console.log(`Player ${getPlayerName(player)}: Position=${position}, IsStarting=${isStarting}`);
    return isStarting;
  });

  const startingQB = startingPlayers.find(player => getPlayerPosition(player) === 'QB');
  const startingRBs = startingPlayers.filter(player => getPlayerPosition(player) === 'RB');

  console.log('Found starting QB:', startingQB ? getPlayerName(startingQB) : 'None');
console.log('Found starting RBs:', startingRBs.map(rb => getPlayerName(rb)));

console.log('startingQB value:', startingQB);
console.log('startingRBs value:', startingRBs);

  if (startingQB && startingRBs.length > 0) {
    console.log('Processing QB stats...');
    
    // Process QB Stats
    const qbStats: QBStats = {
      name: getPlayerName(startingQB),
      passingYards: 0,
      interceptions: 0
    };

    // Get QB stats with detailed error checking
    const qbStatsElement = startingQB.getElementsByTagName("player_stats")[0];
    console.log('QB stats element found:', !!qbStatsElement);

    if (qbStatsElement) {
      const statsCollection = qbStatsElement.getElementsByTagName("stats")[0];
      console.log('QB stats collection found:', !!statsCollection);

      if (statsCollection) {
        const stats = Array.from(statsCollection.getElementsByTagName("stat"));
        console.log(`Found ${stats.length} QB stat entries`);

        stats.forEach(stat => {
          const statId = stat.getElementsByTagName("stat_id")[0]?.textContent;
          const value = stat.getElementsByTagName("value")[0]?.textContent;
          console.log(`QB Stat Found - ID: ${statId}, Value: ${value}`);

          if (statId === STAT_IDS.PASSING_YARDS) {
            qbStats.passingYards = parseFloat(value || "0");
            console.log('Set QB passing yards:', qbStats.passingYards);
          } else if (statId === STAT_IDS.INTERCEPTIONS) {
            qbStats.interceptions = parseFloat(value || "0");
            console.log('Set QB interceptions:', qbStats.interceptions);
          }
        });
      }
    }

    console.log('Final QB Stats:', qbStats);

    // Process RB Stats
    console.log('Processing RB stats...');
    const rbStats: RBStats[] = [];

    for (const rb of startingRBs) {
      console.log(`Processing RB: ${getPlayerName(rb)}`);
      
      const rbStat: RBStats = {
        name: getPlayerName(rb),
        receptions: 0,
        fumbles: 0
      };

      const rbStatsElement = rb.getElementsByTagName("player_stats")[0];
      console.log('RB stats element found:', !!rbStatsElement);

      if (rbStatsElement) {
        const statsCollection = rbStatsElement.getElementsByTagName("stats")[0];
        console.log('RB stats collection found:', !!statsCollection);

        if (statsCollection) {
          const stats = Array.from(statsCollection.getElementsByTagName("stat"));
          console.log(`Found ${stats.length} RB stat entries`);

          stats.forEach(stat => {
            const statId = stat.getElementsByTagName("stat_id")[0]?.textContent;
            const value = stat.getElementsByTagName("value")[0]?.textContent;
            console.log(`RB Stat Found - ID: ${statId}, Value: ${value}`);

            if (statId === STAT_IDS.RECEPTIONS) {
              rbStat.receptions = parseFloat(value || "0");
              console.log('Set RB receptions:', rbStat.receptions);
            } else if (statId === STAT_IDS.FUMBLES) {
              rbStat.fumbles = parseFloat(value || "0");
              console.log('Set RB fumbles:', rbStat.fumbles);
            }
          });
        }
      }

      rbStats.push(rbStat);
      console.log('Added RB stats:', rbStat);
    }

    console.log('All RB Stats:', rbStats);

    // Calculate formula inputs
    const totalRBReceptions = rbStats.reduce((sum, rb) => sum + rb.receptions, 0);
    const totalRBFumbles = rbStats.reduce((sum, rb) => sum + rb.fumbles, 0);

    console.log('Formula Inputs:', {
      passingYards: qbStats.passingYards,
      totalRBReceptions,
      interceptions: qbStats.interceptions,
      totalRBFumbles
    });

    // Calculate formula result
    console.log('Calculating formula result...');
    const formulaResult = calculateQBRBFormula(
      qbStats.passingYards,
      totalRBReceptions,
      qbStats.interceptions,
      totalRBFumbles
    );

    console.log('Formula result:', formulaResult);

    // Set position stats
    positionStats['QB_RB_FORMULA'] = {
      playerName: `${qbStats.name} with ${rbStats.map(rb => rb.name).join(' & ')}`,
      points: formulaResult,
      details: { qbStats, rbStats }
    };

    console.log('Set position stats:', positionStats['QB_RB_FORMULA']);
  } else {
    console.log('Missing required players:', {
      hasQB: !!startingQB,
      numRBs: startingRBs.length
    });
  
    positionStats['QB_RB_FORMULA'] = {
      playerName: 'No valid QB-RB combination found',
      points: 0,
      details: {
        qbStats: { name: 'No QB', passingYards: 0, interceptions: 0 },
        rbStats: []
      }
    };
  }
}
  
      // Handle TOTAL_YARDS
      if (positions.includes('TOTAL_YARDS')) {
        let totalYards = 0;
        let playerYardsList: string[] = [];
  
        playerElements.forEach(playerElement => {
          const selectedPosition = getPlayerPosition(playerElement);
          
          if (!selectedPosition || selectedPosition === 'BN' || selectedPosition === 'IR') {
            return;
          }
  
          const playerName = getPlayerName(playerElement);
          const playerStats = getPlayerStats(playerElement);
          let playerYards = 0;
  
          playerStats.forEach(stat => {
            const statId = getElementTextContent(stat, "stat_id");
            const value = parseFloat(getElementTextContent(stat, "value") || "0");
            
            if (statId === STAT_IDS.PASSING_YARDS || 
                statId === STAT_IDS.RUSHING_YARDS || 
                statId === STAT_IDS.RECEIVING_YARDS) {
              playerYards += value;
            }
          });
  
          if (playerYards > 0) {
            playerYardsList.push(`${playerName}: ${playerYards}`);
            totalYards += playerYards;
          }
        });
  
        positionStats['TOTAL_YARDS'] = {
          playerName: playerYardsList.join(', '),
          points: totalYards
        };
      }
  
      // Handle RB1+RB2 and WR1+WR2
      if (positions.includes('RB1+RB2') || positions.includes('WR1+WR2')) {
        const rbScores: Array<{ name: string; points: number }> = [];
        const wrScores: Array<{ name: string; points: number }> = [];
  
        playerElements.forEach(playerElement => {
          const selectedPosition = getPlayerPosition(playerElement);
          if (!selectedPosition || selectedPosition === 'BN' || selectedPosition === 'IR') return;
  
          const playerName = getPlayerName(playerElement);
          const pointsElement = playerElement.getElementsByTagName("player_points")[0]
            ?.getElementsByTagName("total")[0];
          const points = parseFloat(pointsElement?.textContent || "0");
  
          if (selectedPosition === 'RB') {
            rbScores.push({ name: playerName, points });
          } else if (selectedPosition === 'WR') {
            wrScores.push({ name: playerName, points });
          }
        });
  
        if (positions.includes('RB1+RB2')) {
          rbScores.sort((a, b) => b.points - a.points);
          const topTwoRBs = rbScores.slice(0, 2);
          const totalPoints = topTwoRBs.reduce((sum, rb) => sum + rb.points, 0);
          
          positionStats['RB1+RB2'] = {
            playerName: topTwoRBs.map(rb => rb.name).join(' & '),
            points: totalPoints
          };
        }
  
        if (positions.includes('WR1+WR2')) {
          wrScores.sort((a, b) => b.points - a.points);
          const topTwoWRs = wrScores.slice(0, 2);
          const totalPoints = topTwoWRs.reduce((sum, wr) => sum + wr.points, 0);
          
          positionStats['WR1+WR2'] = {
            playerName: topTwoWRs.map(wr => wr.name).join(' & '),
            points: totalPoints
          };
        }
      }
  
      return positionStats;
    } catch (error) {
      console.error('Error fetching team stats:', error);
      throw error;
    }
  };

  // Results Calculation
  const refreshResults = async () => {
    if (selectedTeams.length < 2) {
      setError('Please select at least two teams');
      return;
    }

    if (!challenge) {
      setError('No active challenge found');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const teamResults: TeamResult[] = [];
  
      for (const teamId of selectedTeams) {
        const teamStats = await getTeamStats(teamId, challenge.positions, challenge.week);
        const teamData = yahooLeague.teams.find(t => t.team_id === teamId);
        
        if (!teamData) continue;
  
        const positionResults = challenge.positions.map(position => ({
          position,
          playerName: teamStats[position]?.playerName || 'N/A',
          points: teamStats[position]?.points || 0,
          details: teamStats[position]?.details 
        }));
  
        teamResults.push({
          name: teamData.name,
          totalPoints: positionResults.reduce((sum, pos) => sum + pos.points, 0),
          positionResults
        });
      }
  
      teamResults.sort((a, b) => b.totalPoints - a.totalPoints);
  
      const newResult = {
        teams: teamResults,
        winner: teamResults[0].name,
        loser: teamResults[teamResults.length - 1].name,
        week: challenge.week,
        league_key: yahooLeague.league_key,
        challengeTitle: challenge.name
      };
  
      await runTransaction(db, async (transaction) => {
        const historicalDocRef = doc(db, 'weeklyChallengeResults', 'historical');
        const historicalDoc = await transaction.get(historicalDocRef);
  
        if (!historicalDoc.exists()) {
          transaction.set(historicalDocRef, { results: [newResult] });
        } else {
          const existingResults = historicalDoc.data().results || [];
          const updatedResults = existingResults.filter((r: ChallengeResult) => 
            r.week !== challenge.week || r.league_key !== yahooLeague.league_key
          );
          updatedResults.push(newResult);
          transaction.update(historicalDocRef, { results: updatedResults });
        }
      });
  
      setResult(newResult);
      setHistoricalResults(prevResults => {
        const updated = prevResults.filter(r => r.week !== challenge.week);
        return [...updated, newResult];
      });
  
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while refreshing results');
    } finally {
      setLoading(false);
    }
  };

  // Results Display Component
  const ResultsCard = ({ result, challengeWeek }: { result: ChallengeResult, challengeWeek?: number }) => (
    <div className="w-full bg-white shadow-lg rounded-xl overflow-hidden mb-8 border-2 border-[#18f7b1]">
      {/* Header */}
      <div className="bg-[#18f7b1] p-4">
        <div className="flex justify-between items-center">
          <div className="flex-grow">
            <div className="flex items-center gap-2">
              <h3 className="text-2xl font-bold text-black">Week {result.week}</h3>
              {result.challengeTitle && (
                <>
                  <span className="text-black mx-2">•</span>
                  <h4 className="text-xl text-black font-medium">
                    {result.challengeTitle}
                  </h4>
                </>
              )}
            </div>
          </div>
          <button 
            className="p-2 hover:bg-black hover:text-white rounded-full transition-colors duration-200"
            aria-label="Share results"
          >
            <Share2 size={24} />
          </button>
        </div>
      </div>
      
      {/* Results Grid */}
      <div className="p-4 grid md:grid-cols-2 gap-4">
        {result.teams.map((team, index) => (
          <div key={index} 
            className={`rounded-lg p-4 ${
              team.name === result.winner 
                ? 'bg-green-50 border-2 border-green-500' 
                : team.name === result.loser 
                  ? 'bg-red-50 border-2 border-red-500'
                  : 'bg-gray-50 border-2 border-gray-200'
            }`}
          >
            {/* Team Header */}
            <div className="flex justify-between items-center mb-3">
              <div>
                <h4 className="font-bold text-lg">{team.name}</h4>
                {team.name === result.winner && (
                  <span className="inline-block bg-green-500 text-white text-xs font-bold px-2 py-1 rounded">
                    Winner 🏆
                  </span>
                )}
                {team.name === result.loser && (
                  <span className="inline-block bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                    Loser 💀
                  </span>
                )}
              </div>
              <span className="text-2xl font-bold">{team.totalPoints.toFixed(2)}</span>
            </div>
            
            {/* Position Results */}
            <div className="space-y-2">
              {team.positionResults.map((posResult, posIndex) => (
                <div key={posIndex} className="flex flex-col p-2 bg-white rounded shadow-sm">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-[#18f7b1]">{posResult.position}</span>
                      <span>{posResult.playerName}</span>
                    </div>
                    <span className="font-medium">{posResult.points.toFixed(2)}</span>
                  </div>
                  
                  {/* QB_RB_FORMULA Details */}
                  {posResult.position === 'QB_RB_FORMULA' && posResult.details && (
                    <div className="font-mono text-sm mt-2">
                      <div className="flex flex-col items-center border-l-4 border-[#18f7b1] pl-2">
                        {/* Formula Display */}
                        <div className="w-full text-center">
                          {/* Numerator */}
                          <div className="mb-1">
                            <span className="text-blue-600">{posResult.details.qbStats.passingYards}</span>
                            <span> × </span>
                            <span className="text-green-600">
                              {posResult.details.rbStats.reduce((sum, rb) => sum + rb.receptions, 0)}
                            </span>
                          </div>
                          
                          {/* Division Line */}
                          <div className="border-b-2 border-black mx-4"></div>
                          
                          {/* Denominator */}
                          <div className="mt-1">
                            1 + {posResult.details.qbStats.interceptions} + {
                              posResult.details.rbStats.reduce((sum, rb) => sum + rb.fumbles, 0)
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // Main render
  return (
    <div className="max-w-6xl mx-auto p-4">
      {/* Challenge Header */}
      <div className="bg-white shadow-lg rounded-xl overflow-hidden mb-8">
        {challenge && (
          <>
            <div className="bg-[#18f7b1] p-6">
              <h3 className="text-2xl font-semibold">{challenge.name}</h3>
            </div>
            
            <div className="p-6 space-y-6">
              <div className="flex flex-wrap gap-2">
                {challenge.positions.map((position) => (
                  <span 
                    key={position} 
                    className="px-3 py-1 bg-[#18f7b1] text-black rounded-full text-sm font-bold"
                  >
                    {position}
                  </span>
                ))}
              </div>
                
              <p>
                <span className="font-bold">Description:</span>{' '}
                <span className="font-normal">{challenge.description}</span>
              </p>
              
              <p>
                <span className="font-bold">Stakes:</span>{' '}
                <span className="font-normal">{challenge.stakes}</span>
              </p>
            </div>
          </>
        )}
      </div>

      {/* Results Section */}
      {result && <ResultsCard result={result} challengeWeek={challenge?.week} />}

      {/* Team Selection */}
      <div className="bg-white shadow-lg rounded-xl overflow-hidden mb-8">
        <div className="p-6">
          <h3 className="text-xl font-semibold mb-4">Select Teams to Compare</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {yahooLeague.teams.map((team) => (
              <label key={team.team_id} 
                className={`flex items-center gap-2 p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                  selectedTeams.includes(team.team_id)
                    ? 'bg-[#18f7b1] text-black'
                    : 'bg-gray-100 hover:bg-gray-200'
                }`}
              >
                <input
                  type="checkbox"
                  value={team.team_id}
                  checked={selectedTeams.includes(team.team_id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedTeams(prev => [...prev, team.team_id]);
                    } else {
                      setSelectedTeams(prev => prev.filter(id => id !== team.team_id));
                    }
                  }}
                  className="form-checkbox h-5 w-5"
                />
                <span className="font-medium">{team.name}</span>
              </label>
            ))}
          </div>

          <button
            className={`mt-6 w-full py-3 px-4 font-bold rounded-lg transition duration-200 ${
              loading || selectedTeams.length < 2
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-[#18f7b1] hover:bg-black text-black hover:text-white'
            }`}
            onClick={refreshResults}
            disabled={loading || selectedTeams.length < 2 || !challenge}
          >
            {loading ? 'Loading...' : 'Refresh Results'}
          </button>

          {error && (
            <p className="mt-4 text-red-500 text-center">{error}</p>
          )}
        </div>
      </div>

      {/* Historical Results Section */}
      <div className="bg-white shadow-lg rounded-xl overflow-hidden">
        <details className="group">
          <summary className="flex justify-between items-center p-6 cursor-pointer list-none">
            <h3 className="text-2xl font-bold">Historical Results</h3>
            <span className="transform group-open:rotate-180 transition-transform duration-200">
              ▼
            </span>
          </summary>
          <div className="p-6 border-t border-gray-200">
            {historicalResults.length > 0 ? (
              <div className="space-y-6">
                {historicalResults
                  .sort((a, b) => b.week - a.week)
                  .map((historicalResult, index) => (
                    <ResultsCard 
                      key={`${historicalResult.league_key}-week-${historicalResult.week}`} 
                      result={historicalResult} 
                    />
                  ))}
              </div>
            ) : (
              <p className="text-center text-gray-500">No historical results available for this league.</p>
            )}
          </div>
        </details>
      </div>
    </div>
  );
};

export default WeeklyChallenge;
                 