import React from 'react';

interface QBRBFormulaStatsProps {
  qbStats: {
    name: string;
    passingYards: number;
    interceptions: number;
  };
  rbStats: Array<{
    name: string;
    receptions: number;
    fumbles: number;
  }>;
}

export const calculateQBRBFormula = (
  passingYards: number,
  rbReceptions: number,
  interceptions: number,
  rbFumbles: number
): number => {
  // Validate inputs
  if (typeof passingYards !== 'number' || isNaN(passingYards)) {
    console.error('Invalid passing yards:', passingYards);
    return 0;
  }
  if (typeof rbReceptions !== 'number' || isNaN(rbReceptions)) {
    console.error('Invalid RB receptions:', rbReceptions);
    return 0;
  }
  if (typeof interceptions !== 'number' || isNaN(interceptions)) {
    console.error('Invalid interceptions:', interceptions);
    return 0;
  }
  if (typeof rbFumbles !== 'number' || isNaN(rbFumbles)) {
    console.error('Invalid RB fumbles:', rbFumbles);
    return 0;
  }

  console.log('Calculating QB-RB Formula with:', {
    passingYards,
    rbReceptions,
    interceptions,
    rbFumbles
  });

  const numerator = passingYards * rbReceptions;
  const denominator = 1 + interceptions + rbFumbles;

  console.log('Formula calculation:', {
    numerator,
    denominator,
    result: numerator / denominator
  });

  // Prevent division by zero
  if (denominator === 0) {
    console.error('Division by zero prevented');
    return 0;
  }

  return numerator / denominator;
};

export const QBRBFormulaStats: React.FC<QBRBFormulaStatsProps> = ({ qbStats, rbStats }) => {
  const totalRBReceptions = rbStats.reduce((sum, rb) => sum + rb.receptions, 0);
  const totalRBFumbles = rbStats.reduce((sum, rb) => sum + rb.fumbles, 0);
  
  const numerator = qbStats.passingYards * totalRBReceptions;
  const denominator = 1 + qbStats.interceptions + totalRBFumbles;
  const result = numerator / denominator;

  return (
    <div className="font-mono text-sm mt-2">
      <div className="flex flex-col items-center border-l-4 border-[#18f7b1] pl-2">
        {/* Formula Display */}
        <div className="w-full text-center">
          {/* Numerator */}
          <div className="mb-1">
            <span className="text-blue-600">{qbStats.passingYards}</span>
            <span> × </span>
            <span className="text-green-600">{totalRBReceptions}</span>
          </div>
          
          {/* Division Line */}
          <div className="border-b-2 border-black"></div>
          
          {/* Denominator */}
          <div className="mt-1">
            1 + {qbStats.interceptions} + {totalRBFumbles}
          </div>
        </div>

        {/* Result */}
        <div className="mt-1 font-bold text-base">
          = {(numerator / denominator).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

