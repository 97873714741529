import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ComingSoonSection() {
  const navigate = useNavigate();

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center">
          {/* Left Column - Content */}
          <div className="max-w-xl">
            <h2 className="text-6xl font-bold leading-none font-antonio text-black mb-6 uppercase">
              Coming Soon
            </h2>
            <p className="text-xl text-gray-600 mb-8 font-antonio">
              Get ready for the ultimate fantasy sports experience. We're building something special 
              that will revolutionize how you manage your fantasy leagues. Sign up now to be among 
              the first to know when we launch and receive exclusive early access.
            </p>
            <button 
              onClick={() => navigate('/signup')}
              className="w-[50%] py-4 mt-6 text-black border border-black/20 rounded-md
                           hover:bg-black hover:text-white transition-all duration-300 
                           font-antonio text-xl uppercase tracking-wide"
            >
              GET ON THE COMMISSIONER WAITLIST
            </button>
          </div>

          {/* Right Column - Image Card */}
          <div className="relative">
            <div className="aspect-[805/845] w-full rounded-2xl overflow-hidden 
                          shadow-xl transition-transform duration-300 hover:scale-[1.02]">
              <div className="absolute inset-0 " />
              <img 
            className="w-full h-full object-cover"
            src={require("../assets/ComingSoonCard.png")}
            alt="Fantasy Loser Cover"
          />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}