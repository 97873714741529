import React, { useState } from 'react';
import { WeeklyChallenge } from '../components/WeeklyChallengeForm';
import { Trash2, Calendar } from 'lucide-react';

interface WeeklyChallengeListProps {
  challenges: WeeklyChallenge[];
  onChallengeSelect: (challengeId: string) => void;
  onChallengeDelete?: (challengeId: string) => void;
  className?: string;
}

const WeeklyChallengeList: React.FC<WeeklyChallengeListProps> = ({
  challenges,
  onChallengeSelect,
  onChallengeDelete,
  className = ''
}) => {
  const [selectedYear, setSelectedYear] = useState<number>(() => {
    const years = challenges.map(c => new Date(c.createdAt as Date).getFullYear());
    return Math.max(...years);
  });

  // Get unique years from challenges
  const years = Array.from(new Set(
    challenges.map(c => new Date(c.createdAt as Date).getFullYear())
  )).sort((a, b) => b - a);

  // Filter challenges by selected year
  const filteredChallenges = challenges.filter(challenge => 
    new Date(challenge.createdAt as Date).getFullYear() === selectedYear
  ).sort((a, b) => b.week - a.week);

  const handleDelete = async (e: React.MouseEvent, challengeId: string) => {
    e.stopPropagation();
    if (onChallengeDelete && window.confirm('Are you sure you want to delete this challenge?')) {
      onChallengeDelete(challengeId);
    }
  };

  const getChallengeStatus = (challenge: WeeklyChallenge) => {
    const now = new Date();
    const createdAt = new Date(challenge.createdAt as Date);
    const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const isWithinWeek = now.getTime() - createdAt.getTime() <= weekInMilliseconds;

    return isWithinWeek ? 'active' : 'completed';
  };

  return (
    <div className={`bg-black rounded-lg p-6 ${className}`}>
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-bold text-[#18f7b1]">Challenge History</h3>
        <div className="flex items-center space-x-2">
          <Calendar className="text-[#18f7b1]" size={20} />
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            className="bg-gray-800 text-white rounded border border-gray-700 px-3 py-1 focus:outline-none focus:ring-2 focus:ring-[#18f7b1]"
          >
            {years.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="space-y-4">
        {filteredChallenges.map((challenge) => {
          const status = getChallengeStatus(challenge);
          return (
            <div
              key={challenge.id}
              onClick={() => onChallengeSelect(challenge.id as string)}
              className={`
                relative rounded-lg p-4 cursor-pointer transition-all
                ${status === 'active' 
                  ? 'bg-gray-800 border-l-4 border-[#18f7b1]' 
                  : 'bg-gray-900 hover:bg-gray-800'}
              `}
            >
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <div className="flex items-center space-x-3">
                    <span className="text-sm font-semibold text-gray-400">Week {challenge.week}</span>
                    {status === 'active' && (
                      <span className="px-2 py-1 text-xs font-semibold text-black bg-[#18f7b1] rounded-full">
                        Active
                      </span>
                    )}
                  </div>
                  <h4 className="text-lg font-semibold text-white mt-1">{challenge.name}</h4>
                  <p className="text-sm text-gray-400 mt-1 line-clamp-2">{challenge.description}</p>
                  
                  {/* Challenge details */}
                  <div className="mt-3 flex flex-wrap gap-2">
                    {challenge.positions.map((position) => (
                      <span
                        key={position}
                        className="px-2 py-1 text-xs font-medium text-[#18f7b1] bg-gray-700 rounded-full"
                      >
                        {position}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Challenge image thumbnail */}
                {challenge.image && (
                  <div className="ml-4 flex-shrink-0">
                    <img
                      src={typeof challenge.image === 'string' ? challenge.image : ''}
                      alt={challenge.name}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  </div>
                )}

                {/* Delete button */}
                {onChallengeDelete && (
                  <button
                    onClick={(e) => handleDelete(e, challenge.id as string)}
                    className="absolute top-2 right-2 p-1 text-gray-400 hover:text-red-500 transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                )}
              </div>
            </div>
          );
        })}

        {filteredChallenges.length === 0 && (
          <div className="text-center py-8 text-gray-400">
            No challenges found for {selectedYear}
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyChallengeList;