import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Menu, X } from 'lucide-react';

interface NavbarProps {
  className?: string; 
}

const Navbar: React.FC<NavbarProps> = ({ className = '' }) => {
  const { user, logout, userRole } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const NavItems = () => (
    <>
      {user ? (
        <>
        <Link to="/explore" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">
        PUNISHMENTS
      </Link>
      <Link to="/challenges" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">
            CHALLENGES
          </Link>
          <Link to="/dashboard" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">
            COMMISSIONERS
          </Link>
          {userRole === 'admin' && (
            <Link to="/admin" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">
              ADMIN
            </Link>
          )}
          
          <button
            onClick={handleLogout}
            className="block w-full text-left py-2 font-antonio px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg"
          >
            LOGOUT
          </button>
        </>
      ) : (
        <>
        <Link to="/explore" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">
            PUNISHMENTS
          </Link>
          <Link to="/challenges" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">
            CHALLENGES
          </Link>
          <Link to="/login" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg">LOGIN</Link>
          
        </>
      )}
    </>
  );

  return (
    <nav className={`bg-[#1A1A1A] sticky top-0 left-0 right-0 z-50 ${className}`}>
      <div className="mx-auto px-4 max-w-screen-xl">
        <div className="flex justify-between items-center py-2 md:py-4">
          <Link to={user ? "/" : "/"} className="flex items-center ml-4 md:ml-8">
            <img 
              className="w-32 sm:w-40 md:w-48 lg:w-64 max-w-[80%] h-auto"
              src={require('../assets/FL_Logo_Primary_Stacked_Rev.png')} 
              alt="FL Logo"
            />
          </Link>
          
          <div className="hidden md:flex items-center space-x-3 mr-4 md:mr-8">
            <NavItems />
          </div>

          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white hover:text-[#18f7b1] focus:outline-none transition-colors duration-300 p-2"
            >
              {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div 
        className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <NavItems />
      </div>
    </nav>
  );
};

export default Navbar;